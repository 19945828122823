import React from 'react';

import { ModalPortal, SecondaryButton } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { WarningIcon } from '../../icons';

import css from './InterruptProcessModal.css';

const InterruptProcessModal = ({
    isOpen,
    onClose,
    handleInterruption,
    headingId,
    descriptionId,
    sidenoteId,
    sidenoteValues,
    actionId,
    showAsPlainText,
}) => (
    <ModalPortal
        id="interrupt-process-modal"
        isOpen={isOpen}
        onClose={onClose}
        containerClassName={css.modalContainer}
        contentClassName={css.modalContent}
        containerClassNameJoined
    >
        <WarningIcon />
        <h3 className={css.modalHeading}>
            {showAsPlainText && headingId ? (
                headingId
            ) : (
                <FormattedMessage id={headingId || 'OnboardingPage.confirmInterruption'} />
            )}
        </h3>
        <p>
            {showAsPlainText && descriptionId ? (
                descriptionId
            ) : (
                <FormattedMessage id={descriptionId || 'OnboardingPage.confirmInterruptionDesc'} />
            )}
        </p>
        <footer className={css.stickyFooter}>
            <p>
                {showAsPlainText && sidenoteId ? (
                    sidenoteId
                ) : (
                    <FormattedMessage
                        id={sidenoteId || 'OnboardingPage.confirmInterruptionSidenote'}
                        values={sidenoteValues || {}}
                    />
                )}
            </p>
            <SecondaryButton onClick={handleInterruption}>
                <FormattedMessage id={actionId || 'OnboardingPage.confirmInterruptionActionText'} />
            </SecondaryButton>
        </footer>
    </ModalPortal>
);

export default InterruptProcessModal;
