import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { createSlug } from '../../util/urlHelpers';
import { propTypes, LINE_ITEM_NIGHT } from '../../util/types';
import { ensureCurrentUser, ensureListing, ensureTransaction } from '../../util/data';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { getAcceptedAndActiveTransactionsData } from '../CalendarPage/CalendarPage.duck.js';
import css from './TransactionPage.css';
import {
    NamedRedirect,
    TransactionPanel,
    Page,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    IconSpinner,
} from '../../components';
import { TopbarContainer } from '../../containers';

import {
    loadData,
    setInitialValues,
    sendMessage,
    sendReview,
    sendFile,
    sendAppointment,
    proceedWithAppointment,
    respondOnCustomerInquiry,
} from './TransactionPage.duck';
import { types as sdkTypes } from '../../util/sdkLoader';

import InboxPanel from './InboxPanel';
import { getUserWishlist } from '../FavoritePage/FavoritePage.duck';
import AsidePanel from './AsidePanel';
import { useListings } from '../../hooks/useListings.js';
import { useIsMobile } from '../../hooks/useIsMobile.js';
import { archieveChat } from '../InboxPage/InboxPage.duck.js';

const { UUID } = sdkTypes;

const PROVIDER = 'provider';
const CUSTOMER = 'customer';

// TransactionPage handles data loading for Sale and Order views to transaction pages in Inbox.
export const TransactionPageComponent = props => {
    const {
        currentUser,
        initialMessageFailedToTransaction,
        transactions,
        history,
        intl,
        params,
        scrollingDisabled,
        transactionRole,
        acceptedAndActiveTransactions,
        onAcceptedTransactionSelect,
        callSetInitialValues,
        fetchInProgress,
        fetchOrdersOrSalesError,
        archievedTransactionsLoading,
        dispatch,
        ...rest
    } = props;
    const [transactionError, setTransactionError] = useState(null);
    const [currentListing, setCurrentListing] = useState(null);
    const [asidePanelVisible, setAsidePanelVisibility] = useState(false);
    const [inboxPanelVisible, setInboxPanelVisible] = useState(false);
    const [transactionPanelVisible, setTransactionPanelVisible] = useState(false);
    const [isMobile, , computing] = useIsMobile(1024);

    const pageId = params.id;
    const isDetailsPage = !!pageId;

    const transaction = (transactions || []).find(({ id: { uuid } }) => uuid === pageId);
    const currentTransaction = ensureTransaction(transaction);
    const currentListingId = currentListing && currentListing.id ? currentListing.id.uuid : null;

    const isProviderRole = transactionRole === PROVIDER;
    const isCustomerRole = transactionRole === CUSTOMER;
    /**
     * in case an owner requests a rider,
     * he becomes a customer, and his previously
     * selected horse listing's id is set as
     * listingSubstitutionId
     */
    const { deleted } = currentListingId ? currentListing.attributes || {} : {};
    const { listingSubstitutionId } = currentTransaction.attributes.protectedData || {};
    const {
        attributes: { emailVerified },
    } = currentUser;

    const authorId =
        listingSubstitutionId && currentTransaction.customer.id
            ? currentTransaction.customer.id.uuid
            : null;

    const [allOwnerListings, listingsReqInProgress] = useListings({
        params: {
            authorId,
        },
        allowed: !!authorId,
    });

    useEffect(() => {
        if (fetchInProgress) return;

        if (isDetailsPage && !transaction && !fetchInProgress) {
            return setTransactionError('fetchFailed');
        }

        setTransactionError(null);
    }, [transaction, fetchInProgress, isDetailsPage]);

    useEffect(() => {
        const isOwnerListing =
            listingSubstitutionId && Array.isArray(allOwnerListings) && allOwnerListings.length > 0;
        const ownerListing =
            isOwnerListing &&
            allOwnerListings.find(({ id: { uuid } }) => uuid === listingSubstitutionId);

        setCurrentListing(ensureListing(ownerListing || currentTransaction.listing));
    }, [allOwnerListings, listingSubstitutionId, currentTransaction.listing]);

    useEffect(() => {
        if (computing) return;

        const changePanelsStates = (inbox, tx, aside) => {
            setInboxPanelVisible(inbox);
            setTransactionPanelVisible(tx);
            setAsidePanelVisibility(aside);
        };

        if (!isDetailsPage) {
            /**
             * for message foute only inbox is visible
             */
            return changePanelsStates(true, false, false);
        }
        if (isDetailsPage && !isMobile) {
            /**
             * for desktop inbox & tx sections are visible
             */
            return changePanelsStates(true, true, false);
        }
        if (isDetailsPage && isMobile) {
            /**
             * for mob details tx section is visible
             */
            return changePanelsStates(false, true, false);
        }
    }, [isDetailsPage, isMobile, computing]);

    useEffect(() => {
        if (computing) return;
        if (isMobile) return;
        if (!currentListingId || currentTransaction.listing.attributes.deleted) return;

        setAsidePanelVisibility(true);
    }, [isMobile, currentListingId, computing]);

    useEffect(() => {
        if (deleted) {
            setAsidePanelVisibility(false);
        }
    }, [currentListingId, deleted]);

    useEffect(() => {
        onAcceptedTransactionSelect();
    }, []);

    const handleSubmitSubscriptionRequest = values => {
        const { bookingDates } = values;

        const initialValues = {
            listing: currentListing,
            transaction: currentTransaction,
            booking: {
                id: new UUID('estimated-booking'),
                type: 'booking',
                attributes: {
                    start: bookingDates.startDate,
                    end: bookingDates.endDate,
                },
            },
            bookingData: {
                unitType: LINE_ITEM_NIGHT,
                quantity: 1,
                startDate: bookingDates.startDate,
                endDate: bookingDates.endDate,
                unitPrice: currentListing.attributes.price,
            },
            bookingDates: {
                bookingStart: bookingDates.startDate,
                bookingEnd: bookingDates.endDate,
            },
        };

        const routes = routeConfiguration();
        const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

        callSetInitialValues(setInitialValues, initialValues);

        history.push(
            createResourceLocatorString(
                'CheckoutPage',
                routes,
                { id: currentListing.id.uuid, slug: createSlug(currentListing) },
                {}
            )
        );
    };

    const deletedListingTitle = intl.formatMessage({
        id: 'TransactionPage.deletedListing',
    });
    const listingTitle = currentListing
        ? currentListing.attributes.deleted
            ? deletedListingTitle
            : currentListing.attributes.title
        : null;

    const isLoading = fetchInProgress || listingsReqInProgress || archievedTransactionsLoading;

    // Redirect users with someone else's direct link to their own inbox/sales or inbox/orders page.
    const isDataAvailable =
        currentUser &&
        currentTransaction.id &&
        currentTransaction.id.uuid === params.id &&
        currentTransaction.customer &&
        currentTransaction.provider &&
        /**
         * publicData info of both customer & provider is necessary for tx
         */
        currentTransaction.customer.attributes.profile.publicData &&
        currentTransaction.provider.attributes.profile.publicData &&
        !isLoading &&
        !transactionError;

    const isOwnSale =
        isDataAvailable &&
        isProviderRole &&
        currentUser.id.uuid === currentTransaction.provider.id.uuid;
    const isOwnOrder =
        isDataAvailable &&
        isCustomerRole &&
        currentUser.id.uuid === currentTransaction.customer.id.uuid;

    if (isDataAvailable && isProviderRole && !isOwnSale) {
        // eslint-disable-next-line no-console
        console.error('Tried to access a sale that was not owned by the current user');
        return <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />;
    } else if (isDataAvailable && isCustomerRole && !isOwnOrder) {
        // eslint-disable-next-line no-console
        console.error('Tried to access an order that was not owned by the current user');
        return <NamedRedirect name="InboxPage" params={{ tab: 'orders' }} />;
    }

    const loadingMessage = isCustomerRole
        ? 'TransactionPage.loadingOrderData'
        : 'TransactionPage.loadingSaleData';

    const initialMessageFailed = !!(
        initialMessageFailedToTransaction &&
        currentTransaction.id &&
        initialMessageFailedToTransaction.uuid === currentTransaction.id.uuid
    );

    const transactionContentClasses = classNames({
        [css.transactionContent]: true,
        [css.transactionContentExpandedDesktop]: !asidePanelVisible,
    });
    const containerRootClassName = classNames(css.sectionContainerRoot, {
        [css.sectionContainerRootConfirmed]: !!emailVerified,
    });
    const showTransactionPanel =
        transactionPanelVisible &&
        isDetailsPage &&
        isDataAvailable &&
        /**
         * hide tx panel for mob if aside is opened
         */
        (isMobile ? !asidePanelVisible : true);

    const showAsidePanel = asidePanelVisible && isDetailsPage && isDataAvailable;
    const listingNotFound = transactionError === 'listingNotFound';

    return (
        <Page
            title={intl.formatMessage({ id: 'TransactionPage.title' }, { title: listingTitle })}
            scrollingDisabled={scrollingDisabled}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar
                    className={classNames({
                        [css.staticTopBar]: !isDetailsPage,
                    })}
                >
                    <TopbarContainer
                        currentPage="OrderMessagesPage"
                        showEmailNotificationBar={!isMobile && !computing}
                    />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain className={css.layout}>
                    {isLoading ? (
                        <IconSpinner />
                    ) : (
                        <section className={css.root}>
                            {fetchInProgress && (
                                <p className={css.loading}>
                                    <FormattedMessage id={`${loadingMessage}`} />
                                </p>
                            )}
                            {inboxPanelVisible && (
                                <aside className={css.inboxContent}>
                                    <InboxPanel
                                        containerRootClassName={containerRootClassName}
                                        navSectionClassName={css.navSection}
                                        transactions={transactions}
                                        currentUser={currentUser}
                                        isMobile={isMobile}
                                        allUserMessages={props.allUserMessages}
                                        fetchOrdersOrSalesError={fetchOrdersOrSalesError}
                                        setInboxPanelVisible={setInboxPanelVisible}
                                        archievedTransactionsError={
                                            props.archievedTransactionsError
                                        }
                                        onArchieveChat={props.onArchieveChat}
                                        params={params}
                                        appointmentEntities={props.appointmentEntities}
                                        intl={intl}
                                        currentListing={currentListing}
                                        currentTransaction={currentTransaction}
                                        listingNotFound={listingNotFound}
                                    />
                                </aside>
                            )}
                            {transactionError && (
                                <p className={css.error}>
                                    <FormattedMessage id={`TransactionPage.${transactionError}`} />
                                </p>
                            )}
                            {((!isLoading && !isDataAvailable) || transactionError) && (
                                <p className={css.infoNotAvailable}>
                                    {transactionError ? (
                                        <FormattedMessage
                                            id={`TransactionPage.${transactionError}`}
                                        />
                                    ) : (
                                        'Diese Konversation ist nicht mehr verfügbar, da das Konto des Mitglieds gelöscht wurde.'
                                    )}
                                </p>
                            )}
                            {showTransactionPanel && (
                                <main className={transactionContentClasses}>
                                    <TransactionPanel
                                        currentUser={currentUser}
                                        initialMessageFailed={initialMessageFailed}
                                        transactionRole={transactionRole}
                                        onSubmitSubscriptionRequest={
                                            handleSubmitSubscriptionRequest
                                        }
                                        acceptedAndActiveTransactions={
                                            acceptedAndActiveTransactions
                                        }
                                        navSectionClassName={css.navSection}
                                        containerRootClassName={containerRootClassName}
                                        asidePanelVisible={asidePanelVisible}
                                        setAsidePanelVisibility={setAsidePanelVisibility}
                                        setInboxPanelVisible={setInboxPanelVisible}
                                        isMobile={isMobile}
                                        {...rest}
                                        transaction={currentTransaction}
                                    />
                                </main>
                            )}
                            {showAsidePanel && (
                                <aside className={css.asideContent}>
                                    <AsidePanel
                                        navSectionClassName={css.navSection}
                                        containerRootClassName={containerRootClassName}
                                        asidePanelVisible={asidePanelVisible}
                                        setAsidePanelVisibility={setAsidePanelVisibility}
                                        transaction={currentTransaction}
                                        currentListing={currentListing}
                                        currentUser={currentUser}
                                        dispatch={props.dispatch}
                                    />
                                </aside>
                            )}
                        </section>
                    )}
                </LayoutWrapperMain>
                <LayoutWrapperFooter className={css.footer}>
                    <footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
};

TransactionPageComponent.defaultProps = {
    currentUser: null,
    acceptSaleError: null,
    declineSaleError: null,
    transaction: null,
    fetchMessagesError: null,
    initialMessageFailedToTransaction: null,
    savePaymentMethodFailed: false,
    sendMessageError: null,
    timeSlots: null,
    fetchTimeSlotsError: null,
};

const { bool, func, oneOf, shape, string, arrayOf, number } = PropTypes;

TransactionPageComponent.propTypes = {
    params: shape({ id: string }).isRequired,
    currentUser: propTypes.currentUser,
    acceptSaleError: propTypes.error,
    appointmentError: string,
    scrollingDisabled: bool.isRequired,
    transaction: propTypes.transaction,
    fetchMessagesError: propTypes.error,
    totalMessagePages: number.isRequired,
    oldestMessagePageFetched: number.isRequired,
    messages: arrayOf(propTypes.message).isRequired,
    initialMessageFailedToTransaction: propTypes.uuid,
    savePaymentMethodFailed: bool,
    sendMessageError: propTypes.error,
    onSendMessage: func.isRequired,
    timeSlots: arrayOf(propTypes.timeSlot),
    fetchTimeSlotsError: propTypes.error,
    callSetInitialValues: func.isRequired,
    onInitializeCardPaymentData: func.isRequired,
    onAppointmentSend: func.isRequired,
    history: shape({
        push: func.isRequired,
    }).isRequired,
    location: shape({
        search: string,
    }).isRequired,

    intl: intlShape.isRequired,
};

const mapStateToProps = state => {
    const { currentUser } = state.user;
    const { userRating, userRatingTotalCount } = state.ProfilePage;
    const { acceptedAndActiveTransactions } = state.CalendarPage;
    const { archievedTransactionsError, archievedTransactionsLoading } = state.InboxPage;

    const {
        fetchInProgress,
        transactionRefs,
        allUserMessages,
        fetchOrdersOrSalesError,
    } = state.InboxPage;

    return {
        transactions: getMarketplaceEntities(state, transactionRefs),
        currentUser: ensureCurrentUser(currentUser),
        // unreadMessagesData,
        scrollingDisabled: isScrollingDisabled(state),
        userRating,
        userRatingTotalCount,
        acceptedAndActiveTransactions,
        fetchInProgress,
        allUserMessages,
        fetchOrdersOrSalesError,
        archievedTransactionsError,
        archievedTransactionsLoading,
        ...state.TransactionPage,
    };
};

const mapDispatchToProps = dispatch => ({
    dispatch,
    onSendMessage: (txId, message) => dispatch(sendMessage(txId, message)),
    onSendFile: (txId, file) => dispatch(sendFile(txId, file)),
    onSendReview: (role, tx, reviewRating, reviewContent) =>
        dispatch(sendReview(role, tx, reviewRating, reviewContent)),
    callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
    onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
    onAcceptedTransactionSelect: () => dispatch(getAcceptedAndActiveTransactionsData()),
    onAppointmentSend: (txId, appointmentData) => dispatch(sendAppointment(txId, appointmentData)),
    onAppointmentHandling: (txId, action) => dispatch(proceedWithAppointment(txId, action)),
    onrespondOnCustomerInquiry: (txData, declineDataMaybe) =>
        dispatch(respondOnCustomerInquiry(txData, declineDataMaybe)),
    onGetUserWishlist: () => dispatch(getUserWishlist()),
    onArchieveChat: (txId, action) => dispatch(archieveChat(txId, action)),
});

const TransactionPage = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(TransactionPageComponent);

TransactionPage.loadData = loadData;
TransactionPage.setInitialValues = setInitialValues;

export default TransactionPage;
