import React, { useState, useEffect, useRef } from 'react';

import { FormattedMessage } from '../../util/reactIntl';
import { SecondaryButton } from '../../components';

import css from './InboxPanel.css';
import { InboxItem } from './components/InboxItem';
import { FilterIcon } from '../../icons';
import Filters, { FILTER_SEARCH_PARAM } from './components/Filters';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { filterTransaction } from './InboxPanel.helpers';
import { useParamFilters } from '../../hooks/useParamFilters';
import { findGreaterDateFromTxData } from '../../util/dates';
import { useScrollY } from '../../hooks/useScrollY';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

const InboxPanel = ({
    allUserMessages,
    currentUser,
    transactions: transactionsFromProps,
    navSectionClassName,
    containerRootClassName,
    fetchOrdersOrSalesError,
    archievedTransactionsError,
    setInboxPanelVisible,
    intl,
    params,
    location,
    onArchieveChat,
    isMobile,
    history,
    ...rest
}) => {
    const [filterPopupVisible, setFilterPopupVisibility] = useState(false);
    const [activeArchivePanelId, setActiveArchivePanelId] = useState(false);
    const [error, setError] = useState(null);
    const [transactions, setTransactions] = useState(transactionsFromProps);
    const [scrollY] = useScrollY(0);

    const filters = useParamFilters(FILTER_SEARCH_PARAM, location);

    const panelRef = useRef();

    const {
        attributes: {
            profile: {
                publicData: { userType },
                privateData: { creditsAvailable = {} },
            },
        },
    } = currentUser;

    const creditsAvailableByType = creditsAvailable[userType] || 0;

    useEffect(() => {
        if (fetchOrdersOrSalesError) {
            return setError('fetchFailed');
        }
        if (archievedTransactionsError) {
            return setError('archievedTransactionsError');
        }
    }, [fetchOrdersOrSalesError, archievedTransactionsError]);

    useEffect(() => {
        const txDataSortedByDate = transactionsFromProps.sort((txFirst, txSecond) => {
            const dateFirst = findGreaterDateFromTxData(txFirst, allUserMessages);
            const dateSecond = findGreaterDateFromTxData(txSecond, allUserMessages);

            return dateSecond - dateFirst;
        });

        setTransactions(txDataSortedByDate);
    }, [transactionsFromProps, allUserMessages]);

    const filtersSelectedNum = filters.length;
    const hasFilters = filtersSelectedNum > 0;

    const pageId = params.id;
    const isMessagesPage = !pageId;

    const isMobileMessagePage = isMessagesPage && isMobile;

    const buttonsSection = (
        <div
            className={classNames(css.buttonsHolder, {
                [css.buttonsHolderMob]: isMobileMessagePage,
                [css.buttonsHolderScrolled]: scrollY > 170,
            })}
        >
            <SecondaryButton
                type="button"
                onClick={() =>
                    history.push(
                        createResourceLocatorString('BalancePage', routeConfiguration(), {}, {})
                    )
                }
            >
                {creditsAvailableByType} Credits
            </SecondaryButton>
            <SecondaryButton
                className={classNames({
                    [css.badgeSelected]: !!hasFilters,
                    [css.focused]: filterPopupVisible,
                })}
                type="button"
                onClick={() => setFilterPopupVisibility(true)}
            >
                <FilterIcon />
                <FormattedMessage id="InboxPanel.navFilterBadge" />
                {hasFilters && <> · {filtersSelectedNum}</>}
            </SecondaryButton>
        </div>
    );

    return (
        <>
            {isMobileMessagePage ? (
                <>
                    <h1 className={css.mobileHeading}>
                        <FormattedMessage id="InboxPanel.navHeading" />
                    </h1>
                    {buttonsSection}
                </>
            ) : (
                <nav className={classNames([navSectionClassName, css.navSection])}>
                    <h1>
                        <FormattedMessage id="InboxPanel.navHeading" />
                    </h1>
                    {buttonsSection}
                </nav>
            )}
            {filterPopupVisible && (
                <Filters
                    isOpen={filterPopupVisible}
                    onClose={() => setFilterPopupVisibility(false)}
                    params={params}
                    filters={filters}
                />
            )}
            {error && (
                <p className={css.error}>
                    <FormattedMessage id={`InboxPage.${error}`} />
                </p>
            )}
            {!error && (
                <ul
                    className={classNames(css.itemList, {
                        [containerRootClassName]: !!containerRootClassName,
                    })}
                    ref={panelRef}
                >
                    {transactions &&
                        (hasFilters
                            ? filterTransaction(
                                  transactions,
                                  filters,
                                  allUserMessages,
                                  currentUser,
                                  pageId
                              )
                            : transactions
                        ).map(tx => (
                            <InboxItem
                                key={tx.id.uuid}
                                tx={tx}
                                allUserMessages={allUserMessages}
                                currentUser={currentUser}
                                setInboxPanelVisible={setInboxPanelVisible}
                                intl={intl}
                                activeArchivePanelId={activeArchivePanelId}
                                setActiveArchivePanelId={setActiveArchivePanelId}
                                isSelected={params.id === tx.id.uuid}
                                onArchieveChat={onArchieveChat}
                                filters={filters}
                                panelRef={panelRef}
                                history={history}
                                {...rest}
                            />
                        ))}
                </ul>
            )}
        </>
    );
};

export default compose(withRouter)(InboxPanel);
