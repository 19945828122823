import { types as sdkTypes, transit } from './sdkLoader';
import Decimal from 'decimal.js';
import config from '../config';
// These helpers are calling FTW's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

export const apiBaseUrl = () => {
    const port = process.env.PORT;
    const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

    // In development, the dev API server is running in a different port
    if (useDevApiServer) {
        return `http://localhost:${port}`;
    }
    if (typeof window !== 'undefined') {
        // Otherwise, use the same domain and port as the frontend
        return `${window.location.origin}`;
    }
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
    // Use Decimal type instead of SDK's BigDecimal.
    {
        type: sdkTypes.BigDecimal,
        customType: Decimal,
        writer: v => new sdkTypes.BigDecimal(v.toString()),
        reader: v => new Decimal(v.value),
    },
];

const serialize = data => {
    return transit.write(data, { typeHandlers, verbose: config.sdk.transitVerbose });
};

const deserialize = str => {
    return transit.read(str, { typeHandlers });
};

const sendAsJSON = body => ({
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    /** body has to be already stringified */
    body,
});

const httpMeth = (path, options) =>
    window
        .fetch(`${apiBaseUrl()}${path}`, options)
        .then(res => res)
        .catch(e => {
            // to do
        });

const get = path =>
    window
        .fetch(`${apiBaseUrl()}${path}`)
        .then(res => res)
        .catch(e => {
            // to do
        });

const post = (path, body, optionsProp) => {
    const url = `${apiBaseUrl()}${path}`;
    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/transit+json',
        },
        body: serialize(body),
    };
    return window
        .fetch(url, optionsProp || options)
        .then(res => {
            const contentTypeHeader = res.headers.get('Content-Type');
            const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

            if (res.status >= 400) {
                return res
                    .json()
                    .then(data => {
                        let e = new Error();
                        e = Object.assign(e, data);
                        throw e;
                    })
                    .catch(e => {
                        return e;
                    });
            }
            if (contentType === 'application/transit+json') {
                return res.text().then(deserialize);
            } else if (contentType === 'application/json') {
                return res.json();
            }
            return res.text();
        })
        .catch(e => {
            return e;
        });
};

export const transitionPrivileged = body => {
    return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Flex with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
    return post('/api/auth/create-user-with-idp', body);
};

export const createUserWithNativeIdp = body => {
    return post('/api/auth/create-user-with-native-idp', body);
};

export const getPolygonCoordinates = body => {
    return post('/api/map/polygon', body);
};

export const denormalize = (key, dataToNormalize) =>
    dataToNormalize.reduce(
        (acc, item) => {
            acc.ids.push(item[key]);
            acc.data[item[key]] = { ...item };
            return acc;
        },
        { ids: [], data: {} }
    );

export const createHorseListingServicesPaylink = body =>
    post('/api/payrexx/horse-listing-services/paylink', body);

export const getlistingPackagesByCountry = body =>
    post('/api/payrexx/horse-listing-services/listingPackages', body);

export const createCreditsPurchasingPaylink = body =>
    post('/api/payrexx/user-services/credits-purchasing-paylink', body);

export const getMatchingScore = body => post('/api/matching-matrix/calculate-score', body);

export const getMatchingScoreBulk = body => post('/api/matching-matrix/calculate-score-bulk', body);

export const sortByMatchingScore = body => post('/api/matching-matrix/sort-by-score', body);

export const updateListingViewsNumber = body => post('/api/sdk/update-views-num', body);

export const sendSGEmail = async body => post('/api/emails/send', body);

export const updateListingDataOnWishlistAction = async (listingId, userId, action) =>
    get(`/api/sdk-listings/favorites/${listingId}/${userId}/${action}`);

export const reflectRirderAvailabilityStatusToListing = async userId =>
    get(`/api/sdk-listings/reflect-availability/${userId}`);

export const getMarketplaceReviews = async perPage =>
    get(`/api/reviews/marketplace-reviews/${perPage}`);

export const requestEntityAssetsList = async (id, variant) =>
    get(`/api/entity-assets/${variant}/${id}/list`);

export const updateEntityAssetsMetadata = async body =>
    post('/api/entity-assets/update-metadata', null, sendAsJSON(body));

export const removeSingleAsset = async body =>
    post('/api/entity-assets/remove-single', null, sendAsJSON(body));

/** filetypes = /png|jpeg|jpg|mp4|ogg|txt|pdf|docx/ */
export const uploadSingleFile = async body =>
    post('/api/entity-assets/upload', null, {
        method: 'POST',
        body,
    });

/** filetypes = /png|jpeg|jpg|mp4/ */
export const uploadSingleAsset = async body =>
    post('/api/entity-assets/upload-asset', null, {
        method: 'POST',
        body,
    });

export const requestSuperDeals = async () => get('/api/super-deals/list');
export const requestClubsOffers = async () => get('/api/clubs-offers/list');

export const updateUserVisitorsData = async (
    userId,
    visitedEntryId,
    visitorRepresentationId,
    entryType
) =>
    get(
        `/api/credits/insert-visitors-data/${userId}/${visitedEntryId}/${visitorRepresentationId}/${entryType}`
    );

export const updateCreditsHistory = async body =>
    post('/api/credits/update', null, sendAsJSON(body));

export const revealUserVisitors = async (
    userId,
    creditsNum,
    visitedEntryId,
    visitorRepresentationId
) =>
    get(
        `/api/credits/reveal-visitors/${userId}/${creditsNum}/${visitedEntryId}/${visitorRepresentationId}`
    );

export const withdrawUserCredits = async (userId, creditsNum) =>
    get(`/api/credits/withdraw/${userId}/${creditsNum}`);

export const handleHttpAction = async (path, options) => httpMeth(path, options);
