import React, { useEffect } from 'react';

import { FormattedMessage } from '../../../util/reactIntl';
import {
    NamedLink,
    UploadUserAvatar,
    Tooltip,
    IconSpinner,
    Button,
    AvailabilityBadge,
    FavoriteIcon,
} from '../../../components';
import { calculateAge, isProfileComplete } from '../../../util/user';
import { ConditionalWrapper } from '../../../util/common';
import {
    USER_AGE,
    USER_EXPERINCE,
    USER_NAME,
    USER_HEIGHT,
    USER_WEIGHT,
} from '../EditUserInfoModalSectionConstants';
import { GET_RECOMMENDATIONS_FORM } from '../../../containers/EditUserInfoPage/EditUserInfoPage';

import LikeIcon from '../icons/LikeIcon';
import HeartIcon from '../icons/HeartIcon';

import css from './EditUserMainInfoSection.css';
import { CheckmarkIcon, EyeIcon, IconCrossL, RoundInfoIcon } from '../../../icons';
import classNames from 'classnames';
import { RECOMMENDATIONS_ID } from '../NavigationBar';
import { addIcludedDataToListing, navigateTo } from '../helpers';
import { useListings } from '../../../hooks/useListings';
import config from '../../../config';
import {
    RIDER_AVAILABILITY_CONFIRMED,
    RIDER_AVAILABILITY_NOT_AVAILABLE,
} from '../../../marketplace-custom-config';

const { userTypeRider, userTypeHorseowner } = config;

const DEFAULT_AVAILABILITY_STATUS = RIDER_AVAILABILITY_NOT_AVAILABLE;

const AcceptInquirySection = ({
    userName,
    rootClassName,
    disabled,
    clickHandler,
    isSameUser,
    riderPage,
    currentUser,
    user,
    isLoggedOut,
    transactionDataInProgress,
}) => {
    const {
        id: { uuid: publicUserId },
        attributes: {
            profile: {
                publicData: { userType: publicUserType },
            },
        },
    } = user;

    const {
        id,
        attributes: {
            profile: {
                publicData: { userType: currentUserType },
            },
        },
    } = currentUser;

    const { uuid: currentUserId } = id || {};

    const ridersViews = currentUserType === userTypeRider && publicUserType === userTypeRider;
    const ownersViews =
        currentUserType === userTypeHorseowner && publicUserType === userTypeHorseowner;

    const riderToOwnerPage = currentUserType === userTypeRider && !riderPage;
    const ownerToRiderPage = currentUserType === userTypeHorseowner && riderPage;

    const authorId =
        isSameUser || ridersViews
            ? null
            : riderToOwnerPage
            ? publicUserId
            : ownerToRiderPage
            ? currentUserId
            : null;

    const [listings, horseownerListingsInProgress, included] = useListings({
        params: {
            authorId,
        },
        allowed: !!authorId,
    });

    const horseownerListings =
        listings && included ? addIcludedDataToListing(listings, included) : null;

    const noListingsForOwner =
        !ownersViews &&
        ((horseownerListings && horseownerListings.length === 0) || !horseownerListings);

    const inquiryDisabled = isLoggedOut
        ? false
        : (disabled && !isSameUser) || noListingsForOwner || ridersViews || ownersViews;

    const resolveInquiryDisabledKey = () => {
        return (
            (!ridersViews && noListingsForOwner && 'noListingsForOwner') ||
            (inquiryDisabled && 'inquiryDisabled')
        );
    };

    return horseownerListingsInProgress || transactionDataInProgress ? (
        <IconSpinner />
    ) : (
        <aside
            className={classNames({
                [css.acceptInquirySection]: true,
                [rootClassName]: !!rootClassName,
            })}
        >
            {!disabled && (
                <p>
                    <FormattedMessage
                        id={
                            isSameUser
                                ? 'ProfileSettingsPage.acceptRejectInfoSameUser'
                                : 'ProfileSettingsPage.acceptRejectInfo'
                        }
                        values={{ userName }}
                    />
                </p>
            )}
            <ConditionalWrapper
                condition={inquiryDisabled && !isSameUser}
                wrapper={children => (
                    <Tooltip
                        position="top"
                        tooltipClassName={css.inquiryDisabledTooltip}
                        content={
                            <div>
                                <p>
                                    <FormattedMessage
                                        id={`ProfileSettingsPage.action-${resolveInquiryDisabledKey()}-heading`}
                                    />
                                </p>
                                <p>
                                    <FormattedMessage
                                        id={`ProfileSettingsPage.action-${resolveInquiryDisabledKey()}-desc`}
                                        values={{ userName }}
                                    />
                                </p>
                            </div>
                        }
                    >
                        {children}
                    </Tooltip>
                )}
            >
                <Button
                    type="button"
                    disabled={
                        isLoggedOut
                            ? false
                            : disabled ||
                              isSameUser ||
                              noListingsForOwner ||
                              ridersViews ||
                              ownersViews
                    }
                    onClick={clickHandler}
                >
                    <FormattedMessage id="EditUserMainInfoSection.submitButtonText" />
                    {isSameUser}
                </Button>
            </ConditionalWrapper>
        </aside>
    );
};

const EditUserMainInfoSection = ({
    form,
    user,
    currentUser,
    uploadInProgress,
    isPublic,
    riderPage,
    onImageUpload,
    renderEditPencilIcon,
    image,
    uploadImageError,
    onUpdateProfile,
    externalReviewsRequests,
    recommendations: recommendationsFromProps,
    handleInquiryModalVisibility,
    isSameUser,
    handleEditSection,
    inquiryDisabled,
    riderListing,
    scoreData,
    isLoggedOut,
    dispatch,
    transactionDataInProgress,
    intl,
}) => {
    const { attributes, profileImage: profileImageFromUserData } = user;

    const { profile } = attributes;
    const { publicData, displayName } = profile;
    const {
        birthDate = {},
        experience: userExperience,
        userType,
        experienceRegularity,
        experienceFocus,
        userHeight,
        userWeight,
        availabilityStatus = DEFAULT_AVAILABILITY_STATUS,
    } = publicData;

    const { baseScoreBreakdown: score } = scoreData || {};
    const userBodyParamsMatches =
        score && isPublic ? score.minimumAgeScore && score.minimumAgeScore.total : false;
    const recommendations = (recommendationsFromProps || []).filter(r => r.status === 'approved');

    const { isPrimaryFieldsComplete } = isProfileComplete(user);

    const {
        attributes: {
            profile: {
                publicData: { userType: currentUserType },
            },
        },
    } = currentUser;
    const currentUserId = currentUser && currentUser.id ? currentUser.id.uuid : null;

    const [firstName] = (displayName || '').split(' ');

    const profileImageId = profileImageFromUserData ? profileImageFromUserData.id : null;
    const profileImage = image || { imageId: profileImageId };

    const userAge =
        birthDate && Object.entries(birthDate).length !== 0 ? calculateAge(birthDate) : null;

    const notAvailable = availabilityStatus === RIDER_AVAILABILITY_NOT_AVAILABLE;
    const isRider = currentUserType === 'rider';
    const noFavorite = inquiryDisabled || isSameUser || isRider || notAvailable;

    const resolveActionDisabledKey = () => {
        const usersAreRiders = riderPage && isRider && 'usersAreRiders';
        const isSameUserKey = isSameUser && 'isSameUser';
        const notAvailableKey = notAvailable && RIDER_AVAILABILITY_NOT_AVAILABLE;

        return isSameUserKey || usersAreRiders || notAvailableKey || 'inquiryDisabled';
    };

    const actionDisabledKey = resolveActionDisabledKey();

    const recommendAndCheckAvailabilityHolder = riderPage && (
        <aside
            className={classNames(css.recommendAndCheckAvailabilityHolder, {
                [css.recommendAndCheckAvailabilityHolderOwn]: !isPublic && isSameUser,
                [css.recommendAndCheckAvailabilityHolderPublic]:
                    isPublic && !isPrimaryFieldsComplete,
            })}
        >
            {isPublic && (
                <ConditionalWrapper
                    condition={noFavorite || !riderListing}
                    wrapper={children => (
                        <Tooltip
                            targetClassName={css.tooltipRecommendTarget}
                            tooltipClassName={classNames({
                                [css.tooltip]: true,
                                [css.recommendUserTooltip]: true,
                            })}
                            content={
                                <>
                                    <p>
                                        <FormattedMessage
                                            id={`ProfileSettingsPage.action-${actionDisabledKey}-heading`}
                                        />
                                    </p>
                                    <p>
                                        <FormattedMessage
                                            id={`ProfileSettingsPage.action-${actionDisabledKey}-desc`}
                                            values={{ userName: firstName }}
                                        />
                                    </p>
                                </>
                            }
                        >
                            {children}
                        </Tooltip>
                    )}
                >
                    {noFavorite || !riderListing ? (
                        <HeartIcon
                            rootClassName={classNames([css.recommendUserIcon, css.inquiryDisabled])}
                        />
                    ) : (
                        <div className={css.favorite}>
                            <FavoriteIcon
                                rootClassName={css.favoriteIcon}
                                inWishlistClassName={css.favoriteIconinWishlist}
                                currentListing={riderListing}
                            />
                        </div>
                    )}
                </ConditionalWrapper>
            )}
            {riderPage && (
                <AvailabilityBadge
                    user={user}
                    editAllowed={!isPublic}
                    helpTextAllowed
                    isPublic={isPublic}
                />
            )}
        </aside>
    );

    const experienceRegularityLabel = intl.formatMessage({
        id: `ProfileSettingsForm.experienceSecondaryInfoLabel-${experienceRegularity ||
            'irregular'}`,
    });

    const experienceFocusLabel = intl.formatMessage({
        id: `ProfileSettingsForm.experienceSecondaryInfoLabel-${experienceFocus || 'irregular'}`,
    });

    const experienceLabel = `${userExperience} ${intl.formatMessage({
        id: `ProfileSettingsPage.experience-${userExperience <= 1 ? 'single' : 'multiple'}`,
    })}`;

    const ExperienceSection = ({ sectionClassName }) => (
        <>
            {userExperience && (
                <div
                    className={classNames(css.sectionItem, {
                        [css.editExperience]: true,
                        [sectionClassName]: !!sectionClassName,
                        [css.sectionItemPublic]: isPublic,
                    })}
                    onClick={() => {
                        if (isPublic) return;
                        handleEditSection(USER_EXPERINCE);
                    }}
                >
                    {userExperience} <FormattedMessage id="ProfileSettingsPage.userExp" />{' '}
                    {renderEditPencilIcon(USER_EXPERINCE, css.smallIcon, null, {
                        onClick: () => null,
                    })}
                    {isPublic && experienceRegularity && experienceFocus && (
                        <Tooltip
                            tooltipClassName={css.experienceTooltip}
                            rootClassName={css.experienceTooltipWrapper}
                            content={
                                <p>
                                    <FormattedMessage
                                        id="ProfileSettingsPage.userExpTooltipContent"
                                        values={{
                                            userName: firstName,
                                            experienceRegularity: experienceRegularityLabel,
                                            experienceFocus: experienceFocusLabel,
                                            experience: experienceLabel,
                                        }}
                                    />
                                </p>
                            }
                        >
                            <RoundInfoIcon />
                        </Tooltip>
                    )}
                </div>
            )}
            {!userExperience && !isPublic && (
                <div
                    onClick={() => handleEditSection(USER_EXPERINCE)}
                    className={classNames(css.sectionItem, css.editExperience, {
                        [sectionClassName]: !!sectionClassName,
                    })}
                >
                    <FormattedMessage id="ProfileSettingsPage.userExpAbsent" />
                </div>
            )}
        </>
    );
    const externalReviewsRequest =
        externalReviewsRequests && externalReviewsRequests[currentUserId];
    const askForRecommendationsMaybe =
        !externalReviewsRequest && !isPublic && recommendations.length === 0;

    const recommendationsNumMaybe = !externalReviewsRequest && recommendations.length > 0;

    const noRecommendationsPublicMaybe =
        !externalReviewsRequest && isPublic && recommendations.length === 0;

    const userBodyParamsList = riderPage
        ? [
              { key: USER_AGE, value: userAge, useKey: true },
              { key: USER_HEIGHT, value: userHeight, useKey: false },
              { key: USER_WEIGHT, value: userWeight, useKey: false },
          ]
        : [{ key: USER_AGE, value: userAge, useKey: true }];

    return (
        <section className={css.upperSection}>
            <div
                className={`${css.userIdentitySection} ${(!isPrimaryFieldsComplete ||
                    availabilityStatus !== RIDER_AVAILABILITY_CONFIRMED) &&
                    !isPublic &&
                    css.userIdentityNonconfirmed}`}
            >
                <div className={`${css.flex} ${css.mob}`}>
                    {recommendAndCheckAvailabilityHolder}
                </div>
                <UploadUserAvatar
                    form={form}
                    currentUser={user}
                    profileImage={profileImage}
                    onImageUpload={onImageUpload}
                    onUpdateProfile={onUpdateProfile}
                    uploadInProgress={uploadInProgress}
                    uploadImageError={uploadImageError}
                    rootClassName={classNames({
                        [css.uploadAvatarRoot]: true,
                        [css.uploadAvatarHiddenControls]: isPublic,
                        [css.publicUserPreview]: isPublic && !isSameUser,
                    })}
                    uploadDisabled={isPublic}
                    previewAllowed={isSameUser}
                />
                <div className={css.userIdentity}>
                    <ExperienceSection sectionClassName={css.desktop} />
                    <section className={css.userNameSection}>
                        <h1>{displayName}.</h1> {renderEditPencilIcon(USER_NAME)}
                        {isPublic && (
                            <div className={`${css.flex} ${css.desktop}`}>
                                {recommendAndCheckAvailabilityHolder}
                            </div>
                        )}
                    </section>
                    {riderPage && externalReviewsRequest && <IconSpinner />}
                    {riderPage && askForRecommendationsMaybe && (
                        <NamedLink
                            name="EditUserInfo"
                            params={{
                                section: GET_RECOMMENDATIONS_FORM,
                                userType: userType || 'rider',
                            }}
                            className={css.recommendationsAllowed}
                        >
                            <LikeIcon />
                            <FormattedMessage id="ProfileSettingsPage.askForRecommendations" />
                        </NamedLink>
                    )}

                    {recommendationsNumMaybe && (
                        <p
                            className={css.recommendationsNumSection}
                            onClick={() => {
                                window.history.pushState(null, null, `#${RECOMMENDATIONS_ID}`);
                                navigateTo(`#${RECOMMENDATIONS_ID}`);
                            }}
                        >
                            <LikeIcon /> {recommendations.length}{' '}
                            <FormattedMessage id="ProfileSettingsPage.recommendations" />
                        </p>
                    )}
                    {noRecommendationsPublicMaybe && riderPage && (
                        <p className={css.noRecommendationsParapgraph}>
                            <FormattedMessage id="ProfileSettingsPage.noRecommendations" />
                        </p>
                    )}
                    <ExperienceSection sectionClassName={css.mob} />
                    <section className={css.sectionItemHolder}>
                        {userBodyParamsList.map(({ key, value, useKey }) => {
                            const isAge = score && key === USER_AGE;
                            const ageParamMatches = userBodyParamsMatches && isAge;
                            const ageParamDoesNotMatch = !userBodyParamsMatches && isAge;

                            return (
                                <React.Fragment key={key}>
                                    {value && (
                                        <div
                                            className={classNames({
                                                [css.sectionItem]: true,
                                                [css.sectionItemPublic]: isPublic,
                                                [css.bodyParamsMatches]: ageParamMatches,
                                                [css.bodyParamDoesNotMatch]: ageParamDoesNotMatch,
                                            })}
                                            onClick={() => {
                                                if (!isPublic) {
                                                    handleEditSection(key);
                                                }
                                            }}
                                        >
                                            {Boolean(isAge) &&
                                                (ageParamMatches ? (
                                                    <CheckmarkIcon />
                                                ) : (
                                                    <IconCrossL />
                                                ))}
                                            {value}{' '}
                                            {useKey && (
                                                <FormattedMessage
                                                    id={`ProfileSettingsPage.${key}`}
                                                />
                                            )}{' '}
                                            {renderEditPencilIcon(key, css.smallIcon, null, {
                                                onClick: () => null,
                                            })}
                                        </div>
                                    )}
                                    {!value && !isPublic && (
                                        <div
                                            className={
                                                value
                                                    ? css.sectionItem
                                                    : classNames([
                                                          css.sectionItem,
                                                          css.sectionItemAction,
                                                      ])
                                            }
                                            onClick={() => {
                                                if (!value) {
                                                    handleEditSection(key);
                                                }
                                            }}
                                        >
                                            <FormattedMessage
                                                id={`ProfileSettingsPage.${key}-absent`}
                                            />
                                            {value ? renderEditPencilIcon(key) : null}
                                        </div>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </section>
                </div>
            </div>
            {isPublic && !isSameUser && riderPage && (
                <AcceptInquirySection
                    currentUser={currentUser}
                    user={user}
                    userName={firstName}
                    isSameUser={isSameUser}
                    disabled={inquiryDisabled}
                    clickHandler={handleInquiryModalVisibility}
                    riderPage={riderPage}
                    dispatch={dispatch}
                    isLoggedOut={isLoggedOut}
                    transactionDataInProgress={transactionDataInProgress}
                />
            )}
            {!isPublic && (
                <div className={css.userPreferencesSection}>
                    {riderPage && (
                        <AvailabilityBadge
                            rootClassName={css.desktop}
                            user={user}
                            isPublic={isPublic}
                            editAllowed
                            helpTextAllowed
                        />
                    )}
                    {user.id ? (
                        <NamedLink
                            className={css.profileLink}
                            name="ProfilePagePreview"
                            params={{ id: user.id.uuid, userType }}
                        >
                            <EyeIcon />
                            <FormattedMessage id="ProfileSettingsPage.viewProfileLink" />
                        </NamedLink>
                    ) : null}
                </div>
            )}
        </section>
    );
};

export default EditUserMainInfoSection;
