import React from 'react';
import PropTypes, { object, string, bool } from 'prop-types';
import { compose } from 'redux';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { withViewport } from '../../util/contextHelpers';
import { parse } from '../../util/urlHelpers';
import { pathByRouteName } from '../../util/routes';
import { propTypes } from '../../util/types';
import {
    Button,
    Logo,
    ModalPortal,
    NamedLink,
    TopbarDesktop,
    TopbarMobileMenu,
    MobileSearchPanel,
} from '../../components';
import SearchIcon from './SearchIcon';
import css from './Topbar.css';
import { supportedCountriesGoogleMapConfig } from '../../util/googleMaps';
import EmailConfirmationBar from './EmailConfirmationBar';
import { IconBell, IconMenu, IconShevronLeft } from '../../icons';
import { isProfileComplete } from '../../util/user';
import { RIDER_AVAILABILITY_NOT_AVAILABLE } from '../../marketplace-custom-config';
import { ensureCurrentUser } from '../../util/data';
import { redirectToURLWithModalState, redirectToURLWithoutModalState } from './Topbar.helpers';
import GenericError from './GenericError';

const { userTypeRider } = config;

const MAX_MOBILE_SCREEN_WIDTH = 1024;

const TopbarComponent = props => {
    const {
        className,
        rootClassName,
        desktopClassName,
        mobileRootClassName,
        mobileClassName,
        isAuthenticated,
        authInProgress,
        currentUser,
        currentUserHasPublishedListings,
        currentUserHasOrders,
        currentPage,
        viewport,
        intl,
        onResendVerificationEmail,
        sendVerificationEmailInProgress,
        sendVerificationEmailError,
        showGenericError,
        genericErrorContent,
        wishlistListingStatusChanged,
        hasDraftListing,
        address: searchAddress,
        currentUserUnreadMessagesRider,
        currentUserUnreadMessagesOwner,
        setShowGenericError,
        onLogout,
        showEmailNotificationBar = true,
        history,
        location,
        ...rest
    } = props;

    const { mobilemenu, mobilesearch, address, bounds } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
    });

    const handleMobileMenuOpen = () => redirectToURLWithModalState(props, 'mobilemenu');
    const handleMobileMenuClose = () => redirectToURLWithoutModalState(props, 'mobilemenu');
    const handleMobileSearchOpen = () => redirectToURLWithModalState(props, 'mobilesearch');
    const handleMobileSearchClose = () => redirectToURLWithoutModalState(props, 'mobilesearch');

    const handleLogout = () => {
        onLogout().then(() => {
            const path = pathByRouteName('LandingPage', routeConfiguration());

            // In production we ensure that data is really lost,
            // but in development mode we use stored values for debugging
            if (config.dev) {
                history.push(path);
            } else if (typeof window !== 'undefined') {
                window.location = path;
            }

            console.log('logged out'); // eslint-disable-line
        });
    };

    const getLocationData = () => {
        // Only render current search if full place object is available in the URL params
        const locationFieldsPresent = address && bounds;

        const noSearchLocationData = locationFieldsPresent
            ? {
                  search: address,
                  selectedPlace: { address, bounds },
              }
            : null;

        const searchData = noSearchLocationData;
        const countryRegExp = new RegExp(
            Object.values(supportedCountriesGoogleMapConfig)
                .map(({ countryString }) => countryString)
                .join('|'),
            'gi'
        );

        searchData &&
            searchData.search &&
            (searchData.search = searchData.search.replace(countryRegExp, ''));

        return searchData;
    };

    const isMobileLayout = viewport.width <= MAX_MOBILE_SCREEN_WIDTH;
    const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
    const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';

    const {
        attributes: {
            profile: {
                publicData: { userType, availabilityStatus },
            },
        },
    } = ensureCurrentUser(currentUser);

    const userIsRider = userType === userTypeRider;

    const { isPrimaryFieldsComplete } = currentUser ? isProfileComplete(currentUser) : {};

    const profileNotComplete =
        (!isPrimaryFieldsComplete || availabilityStatus === RIDER_AVAILABILITY_NOT_AVAILABLE) &&
        userIsRider;

    const notificationCount =
        isAuthenticated &&
        (userIsRider ? currentUserUnreadMessagesRider : currentUserUnreadMessagesOwner);

    const mobileMenu = (
        <TopbarMobileMenu
            isAuthenticated={isAuthenticated}
            wishlistListingStatusChanged={wishlistListingStatusChanged}
            currentUser={currentUser}
            onLogout={handleLogout}
            currentUserUnreadMessagesRider={currentUserUnreadMessagesRider}
            currentUserUnreadMessagesOwner={currentUserUnreadMessagesOwner}
            currentPage={currentPage}
            hasDraftListing={hasDraftListing}
            profileNotComplete={profileNotComplete}
            notificationCount={notificationCount}
        />
    );

    const initialSearchFormValues = { location: getLocationData() };

    const classes = classNames(rootClassName || css.root, className);

    const currentPath = location.pathname;

    const listingPageRegExp = /^\/l\/[\w\d-]+\/[\w\d-]+$/i; // routeConfiguration path for ListingPage: '/l/:slug/:id'
    const isListingPage = listingPageRegExp.test(currentPath);

    const orderDetailsPageRegExp = /^\/order\/[\w\d-]+\/details$/i; // routeConfiguration path for OrderDetailsPage: '/order/:id/details'
    const isOrderDetailsPage = orderDetailsPageRegExp.test(currentPath);

    // if the current page is either ListingPage or OrderDetailsPage or SaleDetailsPage
    // show back icon
    // otherwise show burger menu icon
    const { goBack } = history;
    const showBackButtonIcon = isListingPage || isOrderDetailsPage;
    const showNotificationDot =
        (notificationCount && notificationCount > 0) ||
        profileNotComplete ||
        wishlistListingStatusChanged ||
        hasDraftListing;

    return (
        <div className={classes} data-role="top-bar">
            {showEmailNotificationBar && <EmailConfirmationBar />}
            {/** data-role=top-bar is used for wisepop event to position search map */}
            <div className={classNames(mobileRootClassName || css.container, mobileClassName)}>
                <Button
                    rootClassName={css.menu}
                    onClick={showBackButtonIcon ? goBack : handleMobileMenuOpen}
                    title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
                >
                    {showBackButtonIcon ? (
                        <IconShevronLeft />
                    ) : (
                        <>
                            <IconMenu rootClassName={css.menuIcon} />
                            {isAuthenticated && showNotificationDot && (
                                <code className={css.notificationDot} />
                            )}
                        </>
                    )}{' '}
                </Button>
                <NamedLink name="LandingPage" title={intl.formatMessage({ id: 'Topbar.logoIcon' })}>
                    <Logo format="mobile" />
                </NamedLink>
                <div className={css.topBarNotificationHolderMob}>
                    <code
                        tabIndex="0"
                        role="button"
                        aria-label="Notifications"
                        href="#"
                        className="wisp"
                    >
                        <IconBell />
                    </code>
                    <Button
                        rootClassName={css.searchMenu}
                        onClick={handleMobileSearchOpen}
                        title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
                    >
                        <SearchIcon className={css.searchMenuIcon} />
                    </Button>
                </div>
            </div>
            <div className={css.desktop}>
                <TopbarDesktop
                    className={desktopClassName}
                    wishlistListingStatusChanged={wishlistListingStatusChanged}
                    currentUser={currentUser}
                    currentPage={currentPage}
                    initialSearchFormValues={initialSearchFormValues}
                    intl={intl}
                    isAuthenticated={isAuthenticated}
                    currentUserUnreadMessagesRider={currentUserUnreadMessagesRider}
                    currentUserUnreadMessagesOwner={currentUserUnreadMessagesOwner}
                    onLogout={handleLogout}
                    searchAddress={searchAddress}
                    hasDraftListing={hasDraftListing}
                    profileNotComplete={profileNotComplete}
                    notificationCount={notificationCount}
                    {...rest}
                />
            </div>
            <ModalPortal
                id="TopbarMobileMenu"
                isOpen={isMobileMenuOpen}
                onClose={handleMobileMenuClose}
                containerClassName={css.modalContainerMobile}
                className={css.topBarModalRoot}
                contentClassName={css.topBarModalContent}
                stickyRefNode="layerRef"
                isSticky
            >
                {authInProgress ? null : mobileMenu}
            </ModalPortal>
            <MobileSearchPanel
                userType={userType}
                isOpen={isMobileSearchOpen}
                onClose={handleMobileSearchClose}
            />
            <GenericError
                show={Boolean(showGenericError)}
                content={genericErrorContent}
                onGenericErrorDiscard={() => setShowGenericError(null)}
            />
        </div>
    );
};

TopbarComponent.defaultProps = {
    className: null,
    rootClassName: null,
    desktopClassName: null,
    mobileRootClassName: null,
    mobileClassName: null,
    currentUser: null,
    currentUserHasOrders: null,
    currentPage: null,
    sendVerificationEmailError: null,
    rawSearchParams: null,
    genericErrorContent: null,
    showGenericError: false,
};

const { func, number, shape } = PropTypes;

TopbarComponent.propTypes = {
    className: string,
    rootClassName: string,
    desktopClassName: string,
    mobileRootClassName: string,
    mobileClassName: string,
    isAuthenticated: bool.isRequired,
    authInProgress: bool.isRequired,
    currentUser: propTypes.currentUser,
    currentUserHasPublishedListings: bool.isRequired,
    currentUserHasOrders: bool,
    currentPage: string,
    onLogout: func.isRequired,
    onResendVerificationEmail: func.isRequired,
    sendVerificationEmailInProgress: bool.isRequired,
    sendVerificationEmailError: propTypes.error,
    showGenericError: bool,
    genericErrorContent: string,
    rawSearchParams: object,
    // These are passed from Page to keep Topbar rendering aware of location changes
    history: shape({
        push: func.isRequired,
    }).isRequired,
    location: shape({
        search: string.isRequired,
    }).isRequired,

    // from withViewport
    viewport: shape({
        width: number.isRequired,
        height: number.isRequired,
    }).isRequired,
    // from injectIntl
    intl: intlShape.isRequired,
};

const Topbar = compose(withViewport, injectIntl)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;
