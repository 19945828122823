import React from 'react';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';

import { Form, FieldRadioButton, SecondaryButton } from '../../components';

import { ModalPortal } from '../../components';
import css from './CloseListingModal.css';

const CloseListingModal = ({ onClose, onSubmit, intl }) => (
    <ModalPortal
        id="close-listing-modal"
        containerClassName={css.modalContainer}
        contentClassName={css.modalContent}
        isOpen
        onClose={onClose}
        containerClassNameJoined
        isSticky
    >
        <h3 className={css.modalTitle}>
            <FormattedMessage id="CloseListingModal.title" />
        </h3>
        <p className={css.modalMessage}>
            <FormattedMessage id="CloseListingModal.description" />
        </p>
        <p>
            <FormattedMessage id="CloseListingModal.sidenote" />
        </p>
        <FinalForm
            onSubmit={onSubmit}
            render={fieldRenderProps => {
                const { handleSubmit, values, form } = fieldRenderProps;

                const { reasonToCloseListing } = values;

                return (
                    <Form onSubmit={handleSubmit}>
                        {['no-requests', 'rider-found-here', 'rider-found-elsewhere', 'other'].map(
                            option => (
                                <FieldRadioButton
                                    key={option}
                                    form={form}
                                    id={`${option}-option`}
                                    name="reasonToCloseListing"
                                    label={intl.formatMessage({
                                        id: `CloseListingModal.option-${option}`,
                                    })}
                                    value={intl.formatMessage({
                                        id: `CloseListingModal.option-${option}`,
                                    })}
                                />
                            )
                        )}

                        <footer>
                            <SecondaryButton
                                type="submit"
                                className={css.submitButton}
                                disabled={!reasonToCloseListing}
                            >
                                <FormattedMessage id="CloseListingModal.submitAction" />
                            </SecondaryButton>
                        </footer>
                    </Form>
                );
            }}
        />
    </ModalPortal>
);

export default injectIntl(CloseListingModal);
