import classNames from 'classnames';
import React, { useEffect } from 'react';

import { IconSpinner, AvatarLarge, Button } from '../../components';
import { SQUARE_SMALL_2X } from '../../ducks/Assets.duck';
import ScoreBadge from '../../forms/ProfileSettingsForm/ScoreBadge';
import { useAssets } from '../../hooks/useAssets';
import { useListings } from '../../hooks/useListings';

import { useMatchingMatrix } from '../../hooks/useMatchingMatrix';
import { useRiderListing } from '../../hooks/useRiderListing';
import routeConfiguration from '../../routeConfiguration';
import { insertAuthorDataIntoListing } from '../../util/listings';
import { pathByRouteName } from '../../util/routes';

import { getTimeDiffRepresentation } from '../../util/time';
import { createSlug } from '../../util/urlHelpers';

import css from './VisitorCard.css';
/**
 * when a horseowners is visited
 *
 * visitedEntryId - listingId
 * visitorRepresentationId - riderId
 */

/**
 * when a rider is visited
 *
 * visitedEntryId - riderId
 * visitorRepresentationId - mainHorseId
 */

const commonParams = {
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.square-small',
    ],
};

export default ({
    visitorRepresentationId,
    visitedEntryId,
    visitedAt,
    unlocked,
    representationListingId,
    assetsLoadingRequests,
    handleLockedCardClick,
    deleteVisitor,
    setFulfilledRequests,
    history,
}) => {
    const isRiderPage = !!representationListingId;
    const { days, hours, minutes } = getTimeDiffRepresentation(new Date(visitedAt), new Date());

    const riderListingAuthor = isRiderPage ? null : visitorRepresentationId;

    const [
        riderListing,
        riderListingsRequestInProgress,
        riderIncludedData,
        riderListingReqFulfilled,
    ] = useRiderListing({
        /**
         * no need to request rider listing if a current user is rider
         */
        params: {
            authorId: riderListingAuthor,
            ...commonParams,
        },
    });

    const [listings, inProgress, included, fulfilled] = useListings({
        // allowed: !!representationListingId,
        params: {
            ids: [isRiderPage ? visitorRepresentationId : visitedEntryId],
            ...commonParams,
        },
    });

    const matchingMatrixRiderParams = {
        riderListingId: representationListingId,
        horseOwnerListingId: visitorRepresentationId /** mainHorseId */,
    };

    const matchingMatrixOwnersParams = {
        riderListingId: riderListing && riderListing.id ? riderListing.id.uuid : null,
        horseOwnerListingId: visitedEntryId /** listingId */,
    };

    /* useEffect */
    const [matchingMatrixLoading, matchingScore] = useMatchingMatrix(
        isRiderPage ? matchingMatrixRiderParams : matchingMatrixOwnersParams
    );

    const showScore = matchingScore && matchingScore.finalScore;
    const currentListing = listings && listings[0];

    const listingDataWithAuthor =
        currentListing && included
            ? insertAuthorDataIntoListing(currentListing, included, true)
            : null;
    const riderListingDataWithAuthor =
        riderListing && riderIncludedData
            ? insertAuthorDataIntoListing(riderListing, riderIncludedData)
            : null;

    const avatarUser = isRiderPage ? listingDataWithAuthor : riderListingDataWithAuthor;

    const images = useAssets({
        listing: currentListing,
        allowedTypes: ['image'],
        variant: SQUARE_SMALL_2X,
    });
    const listingImageBeingLoaded =
        currentListing && assetsLoadingRequests && assetsLoadingRequests[currentListing.id.uuid];
    const firstImage = images && images.length > 0 ? images[0] : null;

    const loading = riderListingsRequestInProgress || matchingMatrixLoading || inProgress;

    const handleListingCardClick = () => {
        const slug = createSlug(currentListing);
        const params = { id: currentListing.id.uuid, slug };
        const pathname = pathByRouteName('ListingPage', routeConfiguration(), params);
        history.push(pathname);
    };
    const requestFulfilled =
        !loading &&
        fulfilled &&
        /**
         * if there is riderListingAuthor,
         * check riderListingAuthor
         * otherwise consider it as being fulfilled
         */
        (riderListingAuthor ? riderListingReqFulfilled : true);

    const noData = requestFulfilled && (!currentListing || !avatarUser);
    const dataLoaded = requestFulfilled && currentListing && avatarUser;

    const key = `${visitorRepresentationId}-${visitedEntryId}`;

    useEffect(() => {
        if (dataLoaded) {
            setFulfilledRequests(key);
        }
    }, [dataLoaded]);

    useEffect(() => {
        if (noData) {
            deleteVisitor({
                visitorRepresentationId,
                visitedEntryId,
            });
        }
    }, [noData]);

    return loading ? null : (
        <div
            className={classNames(css.wrapper, {
                [css.locked]: !unlocked,
            })}
        >
            {!unlocked && (
                <div className={css.lockedCardOverlay}>
                    <Button
                        type="button"
                        onClick={() => {
                            handleLockedCardClick({
                                visitorRepresentationId,
                                visitedEntryId,
                            });
                        }}
                    >
                        Ansicht aufdecken
                    </Button>
                </div>
            )}
            <div className={css.container}>
                <div
                    className={classNames(css.imagesHolder, {
                        [css.locked]: !unlocked,
                    })}
                >
                    {avatarUser && avatarUser.author && (
                        <AvatarLarge className={css.avatar} user={avatarUser.author} />
                    )}

                    {listingImageBeingLoaded && <IconSpinner />}
                    {firstImage && (
                        <div
                            className={css.listingImage}
                            onClick={handleListingCardClick}
                            style={
                                firstImage &&
                                firstImage.attributes &&
                                firstImage.attributes.variants &&
                                firstImage.attributes.variants['landscape-crop']
                                    ? {
                                          backgroundImage: `url(${firstImage.attributes.variants['landscape-crop'].url})`,
                                      }
                                    : {}
                            }
                        />
                    )}
                </div>
                {showScore ? (
                    <div className={css.matchingContainer}>
                        <ScoreBadge
                            scoreData={{ maxScore: 100, total: matchingScore.finalScore }}
                            scorePercentageText={`${matchingScore.finalScore}% Übereinstimmung`}
                        />
                    </div>
                ) : (
                    <div className={css.matchingContainerPlaceholder} />
                )}

                <span className={css.visitedAt}>
                    Profil angesehen・Vor{' '}
                    {(days || hours || minutes || '').replace(/Tage/, 'Tagen')}
                </span>
            </div>
        </div>
    );
};
