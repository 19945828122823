import React, { useState } from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { PrimaryButton, Button, ModalPortal, Alert } from '../../../components';
import EditListingDescriptionPageThree from '../../EditListingDescriptionForm/EditListingDescriptionPages/EditListingDescriptionPageThree';
import css from '../EditListingPricingForm.css';
import config from '../../../config';
import { PackageComponents } from './PackageComponent';
import { RoundInfoIconMedium } from '../../../icons';

const EditListingPricingPageOne = ({ values, intl, form, initialAvailability }) => {
    const { availability } = values || {};
    const isFlexible = availability === 'flexible';

    const [modalOn, setModalOn] = useState(false);

    return (
        <>
            <p className={css.page1SubTitle}>
                <FormattedMessage id="EditListingPricingForm.page1SubTitle" />
            </p>

            {!isFlexible && (
                <Alert
                    type="info"
                    header="EditListingPricingForm.notFlexibleWarningTitle"
                    description="EditListingPricingForm.notFlexibleWarningDesc"
                    closeAllowed
                    customIcon={<RoundInfoIconMedium rootClassName={css.infoIcon} />}
                    closeClassName={css.nfwCloseAction}
                    rootClassName={css.notFlexibleWarningAlertRoot}
                >
                    <div className={css.nfwBtnContainer}>
                        <PrimaryButton
                            className={css.nfwBtn}
                            type="button"
                            onClick={() => setModalOn(true)}
                        >
                            <FormattedMessage id="EditListingPricingForm.notFlexibleWarningBtn" />
                        </PrimaryButton>
                    </div>
                </Alert>
            )}

            <ModalPortal
                id="ChangeAvailabilitySettingModal"
                containerClassName={css.modalContainer}
                isOpen={modalOn}
                onClose={() => {
                    form.change('availability', initialAvailability);
                    setModalOn(false);
                }}
            >
                <EditListingDescriptionPageThree
                    intl={intl}
                    currentStep={3}
                    values={values || {}}
                    form={form}
                />

                <Button
                    type="button"
                    className={css.nfwModalBtn}
                    disabled={initialAvailability == availability}
                    onClick={() => setModalOn(false)}
                >
                    <FormattedMessage id="EditListingPricingForm.notFlexibleWarningModalBtn" />
                </Button>
            </ModalPortal>

            <div className={css.packagesWrapper}>
                <PackageComponents
                    config={config}
                    values={values}
                    isFlexible={isFlexible}
                    intl={intl}
                    form={form}
                />
            </div>
        </>
    );
};

export default EditListingPricingPageOne;
