import React, { Component } from 'react';
import { bool, func, instanceOf, object, oneOfType, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName, findRouteByRouteName } from '../../util/routes';
import { propTypes, LINE_ITEM_NIGHT, LINE_ITEM_DAY, DATE_TYPE_DATE } from '../../util/types';
import {
    ensureListing,
    ensureCurrentUser,
    ensureUser,
    ensureTransaction,
    ensureBooking,
    ensureStripeCustomer,
    trimDisplayNameLastWord,
} from '../../util/data';
import {
    formatMoney,
    formatPriceWithApostropheSeparators,
    isRelevantCurrency,
} from '../../util/currency';
import { shapeSimulatedTransaction } from '../../util/transaction';
import {
    BookingBreakdown,
    Logo,
    NamedLink,
    NamedRedirect,
    Page,
    ResponsiveImage,
    StatusPanel,
    StatusPanelItem,
    LayoutWrapperTopbar,
} from '../../components';
import { TopbarContainer } from '../../containers';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
    handleCardPayment,
    retrievePaymentIntent,
    retrieveCouponInfo,
} from '../../ducks/stripe.duck';
import {
    savePaymentMethod,
    createPaymentMethod,
    attachNewPaymentMethod,
    createStripeCustomer,
} from '../../ducks/paymentMethods.duck';

import {
    initiateOrder,
    setInitialValues,
    stripeCustomer,
    confirmPayment,
} from './CheckoutPage.duck';
// import { submitSubscriptionRequest } from '../../containers/TransactionPage/TransactionPage.duck';
import { storeData, storedData, clearData } from './CheckoutPageSessionHelpers';
import { BenefitIcon } from './Icons';
import PaymentGatewayList from './PaymentGatewayList';
import SuccessfulTransactionModal from './SuccessfulTransactionModal';
import css from './CheckoutPage.css';
import {
    CREDIT_CARD_PAYMENT_GATEWAY,
    STORAGE_KEY,
    STRIPE_PI_USER_ACTIONS_DONE_STATUSES,
    ONETIME_PAYMENT,
    PAY_AND_SAVE_FOR_LATER_USE,
    USE_SAVED_CARD,
} from './config';

import sdkConfig from '../../config';
import PhoneNumberBlock from './PhoneNumberBlock';
const CURRENCY = sdkConfig.currency;

const paymentFlow = (selectedPaymentMethod, saveAfterOnetimePayment) => {
    // Payment mode could be 'replaceCard', but without explicit saveAfterOnetimePayment flag,
    // we'll handle it as one-time payment
    return selectedPaymentMethod === 'defaultCard'
        ? USE_SAVED_CARD
        : saveAfterOnetimePayment
        ? PAY_AND_SAVE_FOR_LATER_USE
        : ONETIME_PAYMENT;
};

const initializeOrderPage = (initialValues, routes, dispatch) => {
    const OrderPage = findRouteByRouteName('OrderDetailsPage', routes);

    // Transaction is already created, but if the initial message
    // sending failed, we tell it to the OrderDetailsPage.
    dispatch(OrderPage.setInitialValues(initialValues));
};

export class CheckoutPageComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageData: {},
            dataLoaded: false,
            submitting: false,
            stripeError: null,
            couponError: null,
            couponInfo: null,
            selectedPaymentGateway: null,
            successfulTransactionModalOpen: false,
            successfulTransactionId: null,
        };
        this.stripe = null;

        this.onStripeInitialized = this.onStripeInitialized.bind(this);
        this.loadInitialData = this.loadInitialData.bind(this);
        this.handlePaymentIntent = this.handlePaymentIntent.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCouponInfoRequest = this.handleCouponInfoRequest.bind(this);
        this.handleCouponInput = this.handleCouponInput.bind(this);
        this.handleSuccessfulTransactionVisibility = this.handleSuccessfulTransactionVisibility.bind(
            this
        );
    }

    componentDidMount() {
        if (window) {
            this.loadInitialData();
        }
    }

    /**
     * Load initial data for the page
     *
     * Since the data for the checkout is not passed in the URL (there
     * might be lots of options in the future), we must pass in the data
     * some other way. Currently the ListingPage sets the initial data
     * for the CheckoutPage's Redux store.
     *
     * For some cases (e.g. a refresh in the CheckoutPage), the Redux
     * store is empty. To handle that case, we store the received data
     * to window.sessionStorage and read it from there if no props from
     * the store exist.
     *
     */
    loadInitialData() {
        const {
            bookingData,
            bookingDates,
            booking,
            listing,
            transaction,
            fetchStripeCustomer,
            history,
        } = this.props;

        // Fetch currentUser with stripeCustomer entity
        // Note: since there's need for data loading in "componentWillMount" function,
        //       this is added here instead of loadData static function.
        fetchStripeCustomer();

        // Browser's back navigation should not rewrite data in session store.
        // Action is 'POP' on both history.back() and page refresh cases.
        // Action is 'PUSH' when user has directed through a link
        // Action is 'REPLACE' when user has directed through login/signup process
        const hasNavigatedThroughLink = history.action === 'PUSH' || history.action === 'REPLACE';

        const hasDataInProps =
            !!(bookingData && bookingDates && listing) && hasNavigatedThroughLink;
        if (hasDataInProps) {
            // Store data only if data is passed through props and user has navigated through a link.
            storeData(bookingData, bookingDates, listing, transaction, booking, STORAGE_KEY);
        }

        // NOTE: stored data can be empty if user has already successfully completed transaction.

        const pageData = hasDataInProps
            ? { bookingData, bookingDates, listing, transaction, booking }
            : storedData(STORAGE_KEY);

        this.setState({ pageData: pageData || {}, dataLoaded: true });
    }

    handleSuccessfulTransactionVisibility(response) {
        if (response) {
            /** handle opening modal case */
            this.setState({
                submitting: false,
                stripeError: null,
                successfulTransactionModalOpen: true,
                successfulTransactionId: response.data.data.id.uuid,
            });
        } else {
            /** handle closing modal case */
            const { history, dispatch } = this.props;
            const { successfulTransactionId } = this.state;

            const routes = routeConfiguration();
            const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, {
                id: successfulTransactionId,
            });

            const initialValues = {
                initialMessageFailedToTransaction: null,
                savePaymentMethodFailed: false,
            };

            initializeOrderPage(initialValues, routes, dispatch);

            clearData(STORAGE_KEY);

            history.push(orderDetailsPath);
        }
    }

    handlePaymentIntent(handlePaymentParams) {
        const {
            currentUser,
            onSavePaymentMethod,
            onCreatePaymentMethod,
            onAttachNewPaymentMethod,
            // onSubmitSubscriptionRequest,
        } = this.props;
        throw new Error(
            'Function onSubmitSubscriptionRequest has been removed. Consider a new implementation.'
        );
        const {
            pageData,
            selectedPaymentMethod,
            saveAfterOnetimePayment,
            isCardPayment,
        } = handlePaymentParams;

        const { selectedPaymentGateway } = this.state;
        const { transaction, booking, bookingData } = pageData;
        const storedTx = ensureTransaction(transaction);
        const simulatedTransactionMaybe = storedTx && booking && bookingData;
        const simulatedTransaction = simulatedTransactionMaybe
            ? shapeSimulatedTransaction(storedTx, booking, bookingData)
            : null;
        const ensuredCurrentUser = ensureCurrentUser(currentUser);
        const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);

        const selectedPaymentFlow = paymentFlow(selectedPaymentMethod, saveAfterOnetimePayment);

        const retrieveCustomerId = async () => {
            try {
                const stripeCustomerCreated = ensuredCurrentUser.stripeCustomer;

                if (stripeCustomerCreated) {
                    return {
                        stripeCustomerId:
                            ensuredCurrentUser.stripeCustomer.attributes.stripeCustomerId,
                    };
                }

                const stripeCustomerResponse = await this.props.onCreateStripeCustomer({
                    stripeCustomerEmail: ensuredCurrentUser.attributes.email,
                });
                return {
                    stripeCustomerId: stripeCustomerResponse.attributes.stripeCustomerId,
                };
            } catch (error) {
                return { error: true, message: error.message };
            }
        };

        const prepareSessionStorageData = () => {
            const order = ensureTransaction(simulatedTransaction);

            if (order.id) {
                // Store order.
                const { bookingData, bookingDates, listing, booking } = pageData;
                storeData(bookingData, bookingDates, listing, order, booking, STORAGE_KEY);
                this.setState({ pageData: { ...pageData, transaction: order } });
            }
        };

        /** Step 1: for bank card payment
         * select and save card used in the subscription
         *  */
        const fnHandleCardPayment = async () => {
            try {
                prepareSessionStorageData();

                /** if a user chooses already saved card */
                if (selectedPaymentFlow === USE_SAVED_CARD) {
                    const {
                        stripePaymentMethodId,
                    } = ensuredStripeCustomer.defaultPaymentMethod.attributes;

                    return Promise.resolve({
                        transaction: simulatedTransaction,
                        paymentMethodId: stripePaymentMethodId,
                    });
                } else {
                    const { id: paymentMethodId } = await onCreatePaymentMethod(
                        handlePaymentParams
                    );

                    if (selectedPaymentFlow === ONETIME_PAYMENT) {
                        /** if a user selects a new card and does not save it as a default one
                         * this card will be used only for this subscription
                         * otherwise a default card is used
                         * */
                        const { error, message, stripeCustomerId } = await retrieveCustomerId();

                        if (error) {
                            throw new Error(message);
                        }

                        const { errors } = await onAttachNewPaymentMethod(
                            paymentMethodId,
                            stripeCustomerId
                        );

                        if (errors) {
                            throw new Error(`Failed to attach a bank card to a user.`);
                        }
                    } else if (selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE) {
                        /** if a user selects a new card and saves it as a default one */
                        const { errors } = await onSavePaymentMethod(ensuredStripeCustomer, {
                            stripePaymentMethodId: paymentMethodId,
                        });

                        if (errors) {
                            throw new Error(`Failed to save a bank card as a payment method.`);
                        }
                    }

                    return Promise.resolve({
                        transaction: simulatedTransaction,
                        paymentMethodId,
                    });
                }
            } catch (error) {
                return Promise.reject(error.message);
            }
        };

        /** Step 1: for bank transfer and invoice payment gateways
         * basically, both of these options are handled the same;
         * 1. check that stripe customer is available
         * 2. pass tx data to the next function call
         *  */
        const fnHandleBankTransferPayment = async () => {
            try {
                prepareSessionStorageData();

                /** no need to handle stripeCustomerId from response */
                const { error, message } = await retrieveCustomerId();

                if (error) {
                    throw new Error(message);
                }

                return Promise.resolve({
                    transaction: simulatedTransaction,
                });
            } catch (error) {
                return Promise.reject(error.message);
            }
        };

        // Step 2
        const fnConfirmPayment = (fnParams = {}) => {
            const { couponInfo } = this.state;
            const { paymentMethodId, transaction } = fnParams;
            const initialValues = {
                booking,
                bookingData,
                transaction,
                selectedPaymentGateway,
            };

            if (paymentMethodId) {
                initialValues.paymentMethodId = paymentMethodId;
            }

            if (couponInfo && couponInfo.valid) {
                initialValues.coupon = JSON.stringify(couponInfo);
            }

            // return onSubmitSubscriptionRequest(initialValues);
        };

        const handlePaymentGateway = isCardPayment
            ? fnHandleCardPayment
            : fnHandleBankTransferPayment;

        return handlePaymentGateway()
            .then(result => fnConfirmPayment({ ...result }))
            .catch(error => {
                throw new Error(error);
            });
    }

    handleSubmit(values) {
        const { selectedPaymentGateway, submitting, pageData } = this.state;
        if (submitting) {
            return;
        }
        this.setState({
            submitting: true,
            stripeError: null,
        });

        const { currentUser, paymentIntent } = this.props;

        const {
            card,
            firstName,
            lastName,
            addressLine1,
            addressLine2,
            postalCode,
            city,
            state,
            country,
            saveAfterOnetimePayment,
            paymentMethod,
        } = values;

        const isCardPayment = selectedPaymentGateway === CREDIT_CARD_PAYMENT_GATEWAY;

        if (isCardPayment && !card) {
            return this.setState({
                submitting: false,
                stripeError: {
                    message:
                        'No payment card has been provided. Check out the corresponding field.',
                },
            });
        }

        // Billing address is recommended.
        // However, let's not assume that <StripePaymentAddress> data is among formValues.
        // Read more about this from Stripe's docs
        // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment-no-element
        const addressMaybe =
            addressLine1 && postalCode
                ? {
                      address: {
                          city: city,
                          country: country,
                          line1: addressLine1,
                          line2: addressLine2,
                          postal_code: postalCode,
                          state: state,
                      },
                  }
                : {};
        const billingDetails = {
            name: firstName + lastName,
            email: ensureCurrentUser(currentUser).attributes.email,
            ...addressMaybe,
        };

        const requestPaymentParams = {
            pageData,
            stripe: this.stripe,
            card,
            billingDetails,
            // message,
            paymentIntent,
            selectedPaymentMethod: paymentMethod,
            saveAfterOnetimePayment: !!saveAfterOnetimePayment,
            isCardPayment,
        };

        this.handlePaymentIntent(requestPaymentParams)
            .then(response => {
                this.handleSuccessfulTransactionVisibility(response);
            })
            .catch(error => {
                this.setState({ submitting: false, stripeError: error });
            });
    }

    onStripeInitialized(stripe) {
        this.stripe = stripe;

        const { paymentIntent, onRetrievePaymentIntent } = this.props;
        const tx = this.state.pageData ? this.state.pageData.transaction : null;

        // We need to get up to date PI, if booking is created but payment is not expired.
        const shouldFetchPaymentIntent =
            this.stripe &&
            !paymentIntent &&
            tx &&
            tx.id &&
            tx.booking &&
            tx.booking.id &&
            tx.attributes.protectedData.stripePaymentIntents;

        if (shouldFetchPaymentIntent) {
            const { stripePaymentIntentClientSecret } =
                tx.attributes.protectedData && tx.attributes.protectedData.stripePaymentIntents
                    ? tx.attributes.protectedData.stripePaymentIntents.default
                    : {};

            // Fetch up to date PaymentIntent from Stripe
            onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret });
        }
    }

    handleCouponInfoRequest(coupon) {
        const { onRetrieveCouponInfo, intl } = this.props;
        const couponErrorMessage = intl.formatMessage({ id: 'CheckoutPage.couponErrorMessage' });

        if (!coupon || typeof coupon !== 'string') {
            return this.setState({ couponError: couponErrorMessage });
        }

        this.setState({ submitting: true, couponError: null, couponInfo: null });

        onRetrieveCouponInfo(coupon)
            .then(response => {
                const { couponInfo } = response;
                const { currency, amount_off } = couponInfo;
                const { listing } = this.state.pageData;
                const { price } = listing.attributes;
                const { amount } = price;
                const amountOffExceedsTotalPrice = amount_off > amount;

                if (amountOffExceedsTotalPrice) {
                    const amountOffExceedsTotalPriceError = `Discount sum ${amount_off /
                        100} exceeds total price ${amount / 100}.`;

                    return this.setState({
                        submitting: false,
                        couponError: amountOffExceedsTotalPriceError,
                        couponInfo: null,
                    });
                }

                const currencyIsRelevant = currency ? isRelevantCurrency(currency) : true;

                if (!currencyIsRelevant) {
                    const irrelevantToSdkCurrencyError = `Irrelevant currency for coupon.
            Expected ${CURRENCY}, instead got ${currency}.`;

                    return this.setState({
                        submitting: false,
                        couponError: irrelevantToSdkCurrencyError,
                        couponInfo: null,
                    });
                }

                this.setState({ submitting: false, couponError: null, couponInfo });
            })
            .catch(() => {
                this.setState({
                    submitting: false,
                    couponError: couponErrorMessage,
                    couponInfo: null,
                });
            });
    }

    handleCouponInput() {
        this.setState({ couponError: null, couponInfo: null });
    }

    render() {
        const { scrollingDisabled, intl, params, currentUser, paymentIntent, ...rest } = this.props;

        const {
            couponError,
            couponInfo,
            selectedPaymentGateway,
            successfulTransactionModalOpen,
        } = this.state;

        const isLoading = !this.state.dataLoaded;
        const { listing, bookingDates, booking, transaction, bookingData } = this.state.pageData;
        const existingTransaction = ensureTransaction(transaction);
        const simulatedTransactionMaybe = existingTransaction && booking && bookingData;
        const simulatedTransaction = simulatedTransactionMaybe
            ? shapeSimulatedTransaction(existingTransaction, booking, bookingData)
            : {};
        const currentListing = ensureListing(listing);
        const currentAuthor = ensureUser(currentListing.author);

        const { title: listingTitle, publicData } = currentListing.attributes;
        const { ridingsNum } = publicData;
        const title = intl.formatMessage({ id: 'CheckoutPage.title' });

        const pageProps = { title, scrollingDisabled };
        const topbar = (
            <div className={css.topbar}>
                <NamedLink className={css.home} name="LandingPage">
                    <Logo
                        className={css.logoMobile}
                        title={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
                        format="mobile"
                    />
                    <Logo
                        className={css.logoDesktop}
                        alt={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
                        format="desktop"
                    />
                </NamedLink>
            </div>
        );

        if (isLoading) {
            return <Page {...pageProps}>{topbar}</Page>;
        }

        const isOwnListing =
            currentUser &&
            currentUser.id &&
            currentAuthor &&
            currentAuthor.id &&
            currentAuthor.id.uuid === currentUser.id.uuid;

        const hasListingAndAuthor = !!(currentListing.id && currentAuthor.id);
        const hasBookingDates = !!(
            bookingDates &&
            bookingDates.bookingStart &&
            bookingDates.bookingEnd &&
            booking
        );
        const hasRequiredData = hasListingAndAuthor && hasBookingDates;
        const canShowPage = hasRequiredData && !isOwnListing;
        const shouldRedirect = !isLoading && !canShowPage;

        // Redirect back to ListingPage if data is missing.
        // Redirection must happen before any data format error is thrown (e.g. wrong currency)
        if (shouldRedirect) {
            // eslint-disable-next-line no-console
            console.error(
                'Missing or invalid data for checkout, redirecting back to listing page.',
                {
                    transaction: simulatedTransaction,
                    bookingDates,
                    listing,
                }
            );
            return <NamedRedirect name="ListingPage" params={params} />;
        }

        // Show breakdown only when speculated transaction and booking are loaded
        // (i.e. have an id)

        const tx = existingTransaction.booking ? existingTransaction : simulatedTransaction;

        const txBooking = ensureBooking(tx.booking);

        const breakdown =
            tx.id && txBooking.id ? (
                <BookingBreakdown
                    className={css.bookingBreakdown}
                    userRole="customer"
                    unitType={config.bookingUnitType}
                    transaction={tx}
                    booking={txBooking}
                    dateType={DATE_TYPE_DATE}
                    couponLineItemData={couponInfo}
                />
            ) : null;

        //const isPaymentExpired = checkIsPaymentExpired(existingTransaction);

        const firstImage =
            currentListing.images && currentListing.images.length > 0
                ? currentListing.images[0]
                : null;

        const unitType = config.bookingUnitType;
        const isNightly = unitType === LINE_ITEM_NIGHT;
        const isDaily = unitType === LINE_ITEM_DAY;

        const unitTranslationKey = isNightly
            ? 'CheckoutPage.perNight'
            : isDaily
            ? 'CheckoutPage.perDay'
            : 'CheckoutPage.perUnit';

        const price = currentListing.attributes.price;
        const formattedPrice = formatPriceWithApostropheSeparators(formatMoney(intl, price));
        const detailsSubTitle = `${formattedPrice} ${intl.formatMessage({
            id: unitTranslationKey,
        })}`;

        // If paymentIntent status is not waiting user action,
        // handleCardPayment has been called previously.
        const hasPaymentIntentUserActionsDone =
            paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);

        // If your marketplace works mostly in one country you can use initial values to select country automatically
        // e.g. {country: 'FI'}

        const authorName = currentAuthor
            ? trimDisplayNameLastWord(currentAuthor.attributes.profile.displayName)
            : null;

        return (
            <Page {...pageProps}>
                <LayoutWrapperTopbar>
                    <TopbarContainer />
                </LayoutWrapperTopbar>
                <div className={css.contentContainer}>
                    <div className={css.aspectWrapper}>
                        <ResponsiveImage
                            rootClassName={css.rootForImage}
                            alt={listingTitle}
                            image={firstImage}
                            variants={['landscape-crop', 'landscape-crop2x']}
                        />
                    </div>
                    <div className={css.bookListingContainer}>
                        <div className={css.heading}>
                            <h1 className={css.title}>{title}</h1>
                        </div>
                        <div className={css.benefitsList}>
                            <div className={css.benefitItem}>
                                <BenefitIcon />
                                <FormattedMessage id="CheckoutPage.firstBenefit" />
                            </div>
                            <div className={css.benefitItem}>
                                <BenefitIcon />
                                <FormattedMessage id="CheckoutPage.secondBenefit" />
                            </div>
                            <div className={css.benefitItem}>
                                <BenefitIcon />
                                <FormattedMessage id="CheckoutPage.thirdBenefit" />
                            </div>
                        </div>

                        <div className={css.priceBreakdownContainer}>{breakdown}</div>
                        <PaymentGatewayList
                            currentAuthor={currentAuthor}
                            currentListing={currentListing}
                            currentUser={currentUser}
                            existingTransaction={existingTransaction}
                            listingTitle={listingTitle}
                            paymentIntent={paymentIntent}
                            couponInfo={couponInfo}
                            hasPaymentIntentUserActionsDone={hasPaymentIntentUserActionsDone}
                            inProgress={this.state.submitting}
                            onSubmit={this.handleSubmit}
                            handleCouponInfoRequest={this.handleCouponInfoRequest}
                            handleCouponInput={this.handleCouponInput}
                            onStripeInitialized={this.onStripeInitialized}
                            couponError={couponError}
                            selectedPaymentGateway={selectedPaymentGateway}
                            selectPaymentGateway={selectedPaymentGateway =>
                                this.setState({ selectedPaymentGateway })
                            }
                            stripeError={this.state.stripeError}
                            {...rest}
                        />
                    </div>

                    <div className={css.paymentTabContainer}>
                        <StatusPanel
                            panelClassName={css.panelClassName}
                            transaction={simulatedTransaction}
                            notificationTabClassName={css.notificationTab}
                        >
                            <StatusPanelItem isFinished useIcon>
                                <FormattedMessage id="StatusPanel.enquiryStatus" />
                            </StatusPanelItem>
                            <StatusPanelItem isFinished useIcon>
                                <FormattedMessage id="StatusPanel.subscriptionStatus" />
                            </StatusPanelItem>
                            <StatusPanelItem isActive>
                                <FormattedMessage id="StatusPanel.paymentStatus" />
                            </StatusPanelItem>
                        </StatusPanel>
                        <div className={css.detailsContainerDesktop}>
                            <div className={css.detailsAspectWrapper}>
                                <ResponsiveImage
                                    rootClassName={css.rootForImage}
                                    alt={listingTitle}
                                    image={firstImage}
                                    variants={['landscape-crop', 'landscape-crop2x']}
                                />
                            </div>
                            <div className={css.detailsHeadings}>
                                <h2 className={css.detailsTitle}>
                                    {listingTitle} von {authorName}
                                </h2>
                                <p className={css.detailsSubtitle}>
                                    {ridingsNum && (
                                        <span className={css.ridingsNum}>
                                            {ridingsNum}x pro Woche{' '}
                                        </span>
                                    )}
                                    {detailsSubTitle}
                                </p>
                            </div>
                            {breakdown}
                        </div>
                        <PhoneNumberBlock />
                    </div>
                </div>
                <SuccessfulTransactionModal
                    disableClosing={false}
                    isOpen={successfulTransactionModalOpen}
                    onClose={() => this.handleSuccessfulTransactionVisibility()}
                    {...rest}
                />
            </Page>
        );
    }
}

CheckoutPageComponent.defaultProps = {
    initiateOrderError: null,
    listing: null,
    bookingData: {},
    bookingDates: null,
    transaction: null,
    currentUser: null,
    paymentIntent: null,
};

CheckoutPageComponent.propTypes = {
    scrollingDisabled: bool.isRequired,
    listing: propTypes.listing,
    bookingData: object,
    bookingDates: shape({
        bookingStart: instanceOf(Date).isRequired,
        bookingEnd: instanceOf(Date).isRequired,
    }),
    fetchStripeCustomer: func.isRequired,
    stripeCustomerFetched: bool.isRequired,
    transaction: propTypes.transaction,
    currentUser: propTypes.currentUser,
    params: shape({
        id: string,
        slug: string,
    }).isRequired,
    onConfirmPayment: func.isRequired,
    onInitiateOrder: func.isRequired,
    onHandleCardPayment: func.isRequired,
    onRetrievePaymentIntent: func.isRequired,
    onSavePaymentMethod: func.isRequired,
    initiateOrderError: propTypes.error,
    confirmPaymentError: propTypes.error,
    // handleCardPaymentError comes from Stripe so that's why we can't expect it to be in a specific form
    handleCardPaymentError: oneOfType([propTypes.error, object]),
    paymentIntent: object,

    // from connect
    dispatch: func.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,

    // from withRouter
    history: shape({
        push: func.isRequired,
    }).isRequired,
};

const mapStateToProps = state => {
    const {
        listing,
        booking,
        bookingData,
        bookingDates,
        stripeCustomerFetched,
        transaction,
        initiateOrderError,
        confirmPaymentError,
    } = state.CheckoutPage;

    const { currentUser } = state.user;
    const { handleCardPaymentError, paymentIntent, retrievePaymentIntentError } = state.stripe;

    const {
        stripeCreatePaymentMethodError,
        stripeAttachPaymentMethodError,
        deletePaymentMethodError,
        addPaymentMethodError,
        createStripeCustomerError,
    } = state.paymentMethods;

    return {
        scrollingDisabled: isScrollingDisabled(state),
        currentUser,
        stripeCustomerFetched,
        bookingData,
        bookingDates,
        booking,
        transaction,
        listing,
        handleCardPaymentError,
        confirmPaymentError,
        initiateOrderError,
        paymentIntent,
        retrievePaymentIntentError,
        stripeCreatePaymentMethodError,
        stripeAttachPaymentMethodError,
        deletePaymentMethodError,
        addPaymentMethodError,
        createStripeCustomerError,
    };
};

const mapDispatchToProps = dispatch => ({
    dispatch,
    fetchStripeCustomer: () => dispatch(stripeCustomer()),
    onInitiateOrder: (params, transactionId) => dispatch(initiateOrder(params, transactionId)),
    onRetrievePaymentIntent: params => dispatch(retrievePaymentIntent(params)),
    onHandleCardPayment: params => dispatch(handleCardPayment(params)),
    onConfirmPayment: params => dispatch(confirmPayment(params)),
    onSavePaymentMethod: (stripeCustomer, stripePaymentMethodId) =>
        dispatch(savePaymentMethod(stripeCustomer, stripePaymentMethodId)),
    onCreatePaymentMethod: params => dispatch(createPaymentMethod(params)),
    onAttachNewPaymentMethod: (paymentMethodId, customerId) =>
        dispatch(attachNewPaymentMethod(paymentMethodId, customerId)),
    // onSubmitSubscriptionRequest: txData => dispatch(submitSubscriptionRequest(txData)),
    onRetrieveCouponInfo: coupon => dispatch(retrieveCouponInfo(coupon)),
    onCreateStripeCustomer: customerData => dispatch(createStripeCustomer(customerData)),
});

const CheckoutPage = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(CheckoutPageComponent);

CheckoutPage.setInitialValues = (initialValues, saveToSessionStorage = false) => {
    if (saveToSessionStorage) {
        const { listing, bookingData, bookingDates } = initialValues;
        storeData(bookingData, bookingDates, listing, null, STORAGE_KEY);
    }

    return setInitialValues(initialValues);
};

CheckoutPage.displayName = 'CheckoutPage';

export default CheckoutPage;
