import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { FormattedMessage } from '../../../util/reactIntl';
import { Button, ModalPortal } from '../../../components';
import config from '../../../config';
import css from './RiderNotAvailableModal.css';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routeConfiguration';
import { withRouter } from 'react-router-dom';
import { RIDER_AVAILABILITY_CONFIRMED } from '../../../marketplace-custom-config';
import { updateProfile } from '../../ProfileSettingsPage/ProfileSettingsPage.duck';
import { reflectRirderAvailabilityStatusToListing } from '../../../util/api';

const { userTypeRider } = config;

const RiderNotAvailableModal = ({
    isOpen,
    onClose,
    riderNotAvailableStatus,
    authorDisplayName,
    onUpdateProfile,
    setRetriggerKey,
    currentUserId,
    riderListing,
    riderListingsRequestInProgress,
    history,
}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [retriggeAvailable, setRetriggeAvailable] = useState(false);

    useEffect(() => {
        if (riderListing) {
            setRetriggeAvailable(false);
            return onClose();
        }
        if (!retriggeAvailable || riderListingsRequestInProgress) {
            return;
        }

        setRetriggerKey(new Date().getTime());
    }, [retriggeAvailable, riderListingsRequestInProgress, riderListing]);

    const redirectToProfilePage = riderNotAvailableStatus === 'profileComplete';

    const redirectToProfilePageHandler = () =>
        history.push(
            createResourceLocatorString(
                'ProfileSettingsPage',
                routeConfiguration(),
                { userType: userTypeRider },
                {}
            )
        );

    const updateUserAvailabilityHandler = () => {
        setError(null);
        setLoading(true);

        onUpdateProfile(
            {},
            {
                availabilityStatus: RIDER_AVAILABILITY_CONFIRMED,
                availabilityUpdatedAt: new Date().toISOString(),
            }
        ).then(async () => {
            /**
             * make an api request to re-open rider listing
             * without waiting for an event to handle it
             */
            await reflectRirderAvailabilityStatusToListing(currentUserId)
                .then(async response => {
                    const { error, message } = await response.json();

                    if (error) {
                        throw new Error(message || 'Something went wrong, try later.');
                    }
                    setRetriggeAvailable(true);
                })
                .catch(e => {
                    setError(e.message);
                    setLoading(false);
                });
        });
    };

    return (
        <ModalPortal
            id="ListingPage.RiderNotAvailableModal"
            containerClassName={css.modalContainer}
            contentClassName={css.modalContent}
            containerClassNameJoined
            isOpen={isOpen}
            onClose={onClose}
        >
            <h3 className={css.externalListingModalHeading}>
                <FormattedMessage
                    id={`ListingPage.RiderNotAvailableModal.heading.${riderNotAvailableStatus}`}
                />
            </h3>
            <p className={css.externalListingModalNotification}>
                <FormattedMessage
                    id={`ListingPage.RiderNotAvailableModal.desc.${riderNotAvailableStatus}`}
                    values={{ authorDisplayName }}
                />
            </p>

            <Button
                disabled={error || loading}
                inProgress={loading}
                onClick={() =>
                    (redirectToProfilePage
                        ? redirectToProfilePageHandler
                        : updateUserAvailabilityHandler)()
                }
            >
                <FormattedMessage
                    id={`ListingPage.RiderNotAvailableModal.action.${riderNotAvailableStatus}`}
                />
            </Button>
            {error && <p className={css.error}>{error}</p>}
        </ModalPortal>
    );
};

const mapDispatchToProps = dispatch => ({
    onUpdateProfile: (data, publicData) => dispatch(updateProfile(data, publicData)),
});

export default compose(withRouter, connect(null, mapDispatchToProps))(RiderNotAvailableModal);
