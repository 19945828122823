import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { getListingsById } from '../../ducks/marketplaceData.duck';
import { searchListings } from '../../containers/SearchPage/SearchPage.duck';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import {
    Page,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    ListingCard,
    NamedLink,
    NamedRedirect,
    Alert,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { SuccessIconThin } from '../../icons';
import { resolveCommonClientInfo } from '../../util/email';
import { checkMarketplaceCurrentUser, ensureCurrentUser } from '../../util/data';
import {
    ONBOARDING_INTERRUPTED,
    ONBOARDING_INVITES_SENT,
} from '../../containers/OnboardingPage/OnboardingPage';
import config from '../../config';
import css from './ConfirmationPage.css';
import {
    getTempListingLink,
    removeTempListingLink,
    TEMP_LISTING_SLUG_DATA_KEY,
} from '../../util/localStorage';
import { createSlug, LISTING_PAGE_PARAM_TYPE_DRAFT } from '../../util/urlHelpers';
import InvitesModal from './InvitesModal';

const { userTypeRider, userTypeHorseowner } = config;

const sessionStorageDefined = typeof window !== 'undefined';

const ConfirmationPage = ({
    currentUser: user,
    onSearchMapListings,
    onUpdateProfile,
    listings,
    intl,
}) => {
    const [invitesModalOpen, setInvitesModalOpen] = useState(
        () => sessionStorageDefined && sessionStorage.getItem(ONBOARDING_INVITES_SENT)
    );
    const currentUser = ensureCurrentUser(user || {});

    const {
        id: currentUserId,
        attributes: {
            emailVerified: emailVerifiedAttr,
            profile: { publicData, firstName },
        },
    } = currentUser;

    const { uuid } = currentUserId || { uuid: null };

    const {
        paramsToRedirect,
        userLocation: origin,
        city,
        postalCode,
        emailVerified: emailVerifiedPD,
        userType,
    } = publicData || {};

    const isRider = userType === userTypeRider;
    const emailVerified = emailVerifiedAttr || emailVerifiedPD;

    useEffect(() => {
        if (!origin) {
            return;
        }

        const searchParams = {
            origin: `${origin.lat},${origin.lng}`,
            page: 1,
            perPage: 4,
            // pub_type: 'horse',
            // price: '1,',
            include: ['author', 'author.profileImage', 'images'],
            'fields.user': [
                'profile.displayName',
                'profile.abbreviatedName',
                'profile.bio',
                'profile.publicData.country',
            ],
            'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'createdAt'],
            'fields.image': [
                'variants.landscape-crop',
                'variants.landscape-crop2x',
                'variants.square-small',
            ],
        };

        onSearchMapListings(searchParams, { source: 'ConfirmationPage' });
    }, [origin, isRider]);

    const { [TEMP_LISTING_SLUG_DATA_KEY]: tempListing } = getTempListingLink() || {};

    if (tempListing) {
        /**
         * user being logged out finished 1st step of listing editing
         * redirect him after he finishes onboarding flow with user type horseowner
         */
        removeTempListingLink();

        return (
            <NamedRedirect
                name="EditListingPage"
                params={{
                    id: tempListing.id.uuid,
                    slug: createSlug(tempListing),
                    type: LISTING_PAGE_PARAM_TYPE_DRAFT,
                    tab: 'overview',
                }}
            />
        );
    }

    if (!isRider) {
        return (
            <NamedRedirect
                name="ProfileSettingsPage"
                params={{ id: uuid, userType: userTypeHorseowner }}
                search="confirmation"
            />
        );
    }

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'ConfirmationPage.schemaTitle' }, { siteTitle });
    const onboardingInterrupted =
        sessionStorageDefined && sessionStorage.getItem(ONBOARDING_INTERRUPTED);

    if (paramsToRedirect && paramsToRedirect.slug && paramsToRedirect.id) {
        onUpdateProfile({}, { paramsToRedirect: null });

        return (
            <NamedRedirect
                name="ListingPage"
                params={{ slug: paramsToRedirect.slug, id: paramsToRedirect.id }}
            />
        );
    }

    if (currentUser && onboardingInterrupted) {
        sessionStorageDefined && sessionStorage.removeItem(ONBOARDING_INTERRUPTED);
        return (
            <NamedRedirect name="ProfileSettingsPage" params={{ userType: userType || 'rider' }} />
        );
    }

    const { clientLink } = resolveCommonClientInfo(currentUser.attributes.email);

    const listingsFound = listings && listings.length > 0;

    return (
        <Page
            title={schemaTitle}
            scrollingDisabled={false}
            schema={{
                '@context': 'http://schema.org',
                '@type': 'WebPage',
                name: schemaTitle,
            }}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain>
                    <main className={css.root}>
                        <SuccessIconThin />
                        <h2>
                            <FormattedMessage
                                id="ConfirmationPage.heading"
                                values={{ userName: firstName }}
                            />
                        </h2>
                        {listingsFound && (
                            <p>
                                <FormattedMessage
                                    id="ConfirmationPage.description"
                                    values={{ location: `${postalCode} ${city}` }}
                                />
                            </p>
                        )}
                        <section className={`${css.listingsContainer} ${css.profileNotComplete}`}>
                            {listings.map(l => (
                                <li className={css.listing} key={l.id.uuid}>
                                    <ListingCard listing={l} />
                                </li>
                            ))}
                        </section>

                        <aside
                            className={`${css.visibilityNotification} ${!emailVerified &&
                                css.emailVerifiedOverlay} ${!clientLink &&
                                !emailVerified &&
                                css.emailVerifiedOverlayShifted}`}
                        >
                            <Alert
                                header="ConfirmationPage.visibilityHeading"
                                description="ConfirmationPage.visibilityDesc"
                                rootClassName={css.visibilityHolder}
                            >
                                <NamedLink
                                    name="ProfileSettingsPage"
                                    params={{ userType: userType || 'rider' }}
                                >
                                    <FormattedMessage id="ConfirmationPage.visibilityAction" />
                                </NamedLink>
                            </Alert>
                        </aside>

                        {listingsFound && <div className={css.gradientOverlay} />}
                        {invitesModalOpen && (
                            <InvitesModal
                                isOpen
                                onClose={() => {
                                    sessionStorage.removeItem(ONBOARDING_INVITES_SENT);
                                    setInvitesModalOpen(false);
                                }}
                            />
                        )}
                    </main>
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
};

const mapStateToProps = state => {
    const { currentPageResultIds } = state.SearchPage;
    return {
        currentUser: checkMarketplaceCurrentUser(state),
        listings: getListingsById(state, currentPageResultIds),
    };
};

const mapDispatchToProps = dispatch => ({
    onSearchMapListings: searchParams =>
        dispatch(searchListings(searchParams, { initiator: 'ConfirmationPage' })),
    onUpdateProfile: (data, publicData) => dispatch(updateProfile(data, publicData)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)(ConfirmationPage);
