import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
    Page,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    CreditsPurchasingModal,
    Button,
    Footer,
    NamedLink,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { stringifyDateToISO8601 } from '../../util/dates';
import { ensureCurrentUser } from '../../util/data';

import css from './BalancePage.css';
import { CREDITS_ACTION_REFERRAL_INVITER, getCreditsLabel } from '../../credits-config';
import { usePaymentStatus } from '../../hooks/usePaymentStatus';
import classNames from 'classnames';

export const BalancePage = ({ scrollingDisabled, currentUser, location, history }) => {
    const [modalOpen, setModalOpen] = useState(false);

    const { paymentError, navigateToErrorPage, navigateFromErrorPage } = usePaymentStatus({
        location,
        history,
        pageName: 'BalancePage',
    });

    const schemaTitle = 'Deine Bilanz';
    const schemaDescription = 'Hier findest Du die Gesamtzahl und Historie deiner Credits.';

    const {
        privateData: { creditsAvailable = {}, creditsHistory },
        publicData: { userType },
    } = currentUser.attributes.profile;

    const creditsForUserType = creditsHistory && creditsHistory[userType];
    const creditsHistoryAvailable =
        !paymentError && Array.isArray(creditsForUserType) && creditsForUserType.length > 0
            ? creditsForUserType
            : null;

    const showReferralBlock = creditsHistoryAvailable
        ? creditsHistoryAvailable.every(
              ({ eventName }) => eventName !== CREDITS_ACTION_REFERRAL_INVITER
          )
        : true;

    return (
        <Page
            scrollingDisabled={scrollingDisabled}
            contentType="website"
            description={schemaDescription}
            title={schemaTitle}
            schema={{
                '@context': 'http://schema.org',
                '@type': 'WebPage',
                description: schemaDescription,
                name: schemaTitle,
                image: ['https://horsedeal.imgix.net/static/social-image-1200x630.webp'],
            }}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer currentPage="BalancePage" />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain
                    className={css.layout}
                    blobBackground
                    blobProps={{
                        xDistance: 20,
                        baseWidth: 760,
                    }}
                >
                    <main className={css.container}>
                        <header className={css.header}>
                            <h1 className={css.heading}>Credits</h1>
                            <p className={css.subHeading}>Deine Bilanz</p>
                        </header>
                        <div className={classNames(css.creditsPurchaseBox, css.box)}>
                            <p className={css.supportLink}>
                                <span
                                    className={css.modalLink}
                                    onClick={() => {
                                        window.open(
                                            'https://support.horsedeal.com/hc/de/articles/18591062923538-Was-sind-Credits-und-was-kann-ich-damit-tun',
                                            '_blank'
                                        );
                                    }}
                                >
                                    Was sind Credits?
                                </span>
                            </p>
                            <h2 className={css.creditsPurchaseHeading}>
                                {creditsAvailable[userType] || 0} Credits
                            </h2>
                            <p
                                className={css.creditsPurchaseAction}
                                onClick={() => setModalOpen(true)}
                            >
                                Mehr Credits hinzufügen
                            </p>
                        </div>
                        {showReferralBlock && (
                            <div className={classNames(css.creditsReferralBox, css.box)}>
                                <p>Erhalte 50 Credits kostenlos!</p>
                                <p>
                                    <NamedLink name="ReferralPage">
                                        Empfehlen & Credits erhalten
                                    </NamedLink>
                                </p>
                            </div>
                        )}
                        {creditsHistoryAvailable ? (
                            <>
                                <div className={css.creditsPurchaseHistory}>
                                    <h3>Historie</h3>
                                    <section>
                                        {creditsHistoryAvailable
                                            .sort(
                                                (a, b) =>
                                                    new Date(b.createdAt).getTime() -
                                                    new Date(a.createdAt).getTime()
                                            )
                                            .map((creditItem, index) => (
                                                <div key={index} className={css.creditTxItem}>
                                                    <p>
                                                        {stringifyDateToISO8601(
                                                            new Date(creditItem.createdAt),
                                                            'DD.MM.YYYY'
                                                        )}
                                                    </p>
                                                    <p>{getCreditsLabel(creditItem)}</p>
                                                    <p>
                                                        {!!creditItem.action ? '+' : '-'}{' '}
                                                        {creditItem.total}
                                                    </p>
                                                </div>
                                            ))}
                                    </section>
                                </div>
                                <p className={css.sidenote}>
                                    Einige Gutschriften oder Belastungen, z. B. bei Ablauf von
                                    Credits, werden möglicherweise nicht vollständig in der Historie
                                    aufgeführt.
                                </p>
                            </>
                        ) : (
                            <>
                                <p className={css.error}>
                                    {paymentError
                                        ? 'Zahlung fehlgeschlagen. Bitte versuche es später erneut.'
                                        : 'Die Historie deiner Credits ist momentan nicht verfügbar.'}
                                </p>
                                <Button type="button" onClick={() => navigateFromErrorPage()}>
                                    Zurück
                                </Button>
                            </>
                        )}
                        {modalOpen && (
                            <CreditsPurchasingModal
                                isOpen
                                currentUser={currentUser}
                                onClose={() => setModalOpen(false)}
                                onError={() => {
                                    setModalOpen(false);
                                    navigateToErrorPage();
                                }}
                            />
                        )}
                    </main>
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
};

const mapStateToProps = ({ UI, user: { currentUser } }) => ({
    scrollingDisabled: isScrollingDisabled({ UI }),
    currentUser: ensureCurrentUser(currentUser),
});

export default compose(withRouter, connect(mapStateToProps))(BalancePage);
