import React, { useState } from 'react';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { ToggleText } from '../../../components';
import { USER_BIO } from '../EditUserInfoModalSectionConstants';
import { getHeightByNumberOfLines, trimDisplayNameLastWord } from '../../../util/data';
import { WarningIcon } from '../../../icons';
import { BIO_ID } from '../NavigationBar';
import sectionCss from './BioSection.css';
import css from '../ProfileSettingsForm.css';
import LogoutOverlay from '../LogoutOverlay';

const BIO_NUMBER_OF_LINES = 3;

const BioSection = ({
    renderEditPencilIcon,
    handleEditSection,
    isPublic,
    displayName,
    bio,
    isLoggedOut,
    userName,
    isRider,
    switchUserRoleAction,
    pageParams,
}) => {
    const [bioRef, setBioRef] = useState(null);

    if (isPublic && !bio) return null;

    const bioToggleMaxHeight = bioRef
        ? getHeightByNumberOfLines(bioRef, BIO_NUMBER_OF_LINES)
        : { isExceeded: true, refMaxHeight: 0 };

    const logoutOverlayVisible = isLoggedOut || switchUserRoleAction;

    const classes = classNames({
        [css.infoBlock]: true,
        [css.noInfoBlock]: !bio,
        [css.viewProfileRequestWrapper]: logoutOverlayVisible,
        [sectionCss.viewProfileRequestWrapper]: logoutOverlayVisible,
    });

    return (
        <div className={classes} id={BIO_ID}>
            <h2 className={css.infoHeading}>
                <FormattedMessage
                    id={`ProfileSettingsForm.bioHeading-${
                        isPublic && displayName ? 'public' : 'preview'
                    }`}
                    values={{
                        displayName: trimDisplayNameLastWord(displayName || ''),
                    }}
                />
                {!bio ? (
                    isRider ? (
                        <WarningIcon rootClassName={css.noInfoWarningIcon} />
                    ) : null
                ) : (
                    <div className={css.editBlock}>
                        {renderEditPencilIcon(
                            USER_BIO,
                            null,
                            <p className={css.editText}>
                                <FormattedMessage id="ProfileSettingsForm.editText" />
                            </p>
                        )}
                    </div>
                )}
            </h2>
            {bio ? (
                <ToggleText
                    initialState={isLoggedOut}
                    rootClass={sectionCss.bio}
                    maxHeight={bioToggleMaxHeight.refMaxHeight}
                >
                    <p ref={setBioRef}>{bio}</p>
                </ToggleText>
            ) : (
                <>
                    <div className={sectionCss.placeholder} />
                    <div className={sectionCss.placeholder} />
                    <div className={sectionCss.placeholder} />

                    <button
                        type="button"
                        onClick={() => {
                            handleEditSection(USER_BIO);
                        }}
                        className={`${css.actionItem} ${css.actionBtn}`}
                    >
                        <FormattedMessage id="ProfileSettingsForm.editUserInfoAction-userDescription" />
                    </button>
                </>
            )}
            <LogoutOverlay
                userName={userName}
                isLoggedOut={isLoggedOut}
                rootClassName={sectionCss.viewProfileRequestAction}
                sectionName={USER_BIO}
                switchUserRoleAction={switchUserRoleAction}
                pageParams={pageParams}
            />
        </div>
    );
};

export default BioSection;
