import React from 'react';
import css from './SectionTeamAndLocations.css';

export default () => (
    <section className={css.root}>
        <header>
            <h2 className={css.subHeading}>Team</h2>
            <p className={css.heading}>Tadaa! Das sind wir</p>
            <p className={css.description}>
                {`Wir arbeiten täglich hart daran, unsere Plattform weiterzuentwickeln, \nneue Ideen aus der Community umzusetzen und für ein noch besseres \nNutzererlebnnis zu sorgen.`}
            </p>
        </header>

        <main className={css.grid}>
            <div className={css.gridItem}>
                <div
                    className={css.imageHolder}
                    style={{
                        backgroundImage: `url('https://horsedeal.imgix.net/static/careerPage/team-member-benjamin-kroeni.svg')`,
                    }}
                />
                <h3>Benjamin Kröni</h3>
                <p>
                    Benjamin kümmert sich darum, dass unsere Plattform tut, was sie soll und schnell
                    kann, was Du dir wünschst.
                </p>
            </div>
            <div className={css.gridItem}>
                <div
                    className={css.imageHolder}
                    style={{
                        backgroundImage: `url('https://horsedeal.imgix.net/static/careerPage/team-member-meryem-riahi.svg')`,
                    }}
                />
                <h3>Meryem Riahi</h3>
                <p>
                    Meryem hält die Fäden zusammen, wenn es um spannende Kooperationen und
                    strategische Partnerschaften geht.
                </p>
            </div>
            <div className={css.gridItem}>
                <div
                    className={css.imageHolder}
                    style={{
                        backgroundImage: `url('https://horsedeal.imgix.net/static/careerPage/team-member-gianna-fischer.svg')`,
                    }}
                />
                <h3>Gianna Fischer</h3>
                <p>
                    Gianna bringt unsere Nutzer zum Lächeln und geht für dich die Extrameile für
                    eine tolle Nutzererfahrung.
                </p>
            </div>
            <div className={css.gridItem}>
                <div
                    className={css.imageHolder}
                    style={{
                        backgroundImage: `url('https://horsedeal.imgix.net/static/careerPage/team-member-frank-schlattmann.svg')`,
                    }}
                />
                <h3>Frank Schlattmann</h3>
                <p>
                    Frank ist unser Stimmungsmacher und gleichzeitig der Techie, der unser Tech
                    Stack auf dem neusten Stand hält.
                </p>
            </div>
            <div className={css.gridItem}>
                <div
                    className={css.imageHolder}
                    style={{
                        backgroundImage: `url('https://horsedeal.imgix.net/static/careerPage/team-member-sarah-rodemer.svg')`,
                    }}
                />
                <h3>Sarah Rodemer</h3>
                <p>
                    Sarah ist unsere SEO-Göttin und schreibt für uns knackige und emotionale Texte
                    für Web, Newsletter und Print.
                </p>
            </div>
            <div className={css.gridItem}>
                <div
                    className={css.imageHolder}
                    style={{
                        backgroundImage: `url('https://horsedeal.imgix.net/static/careerPage/team-member-sebastian-thönnes.svg')`,
                    }}
                />
                <h3>Sebastian Thönnes</h3>
                <p>
                    Sebastian ist unser Pixelmaster, denn er kümmert sich um die User Experience und
                    Designs für unsere Plattform.
                </p>
            </div>
            <div className={css.gridItem}>
                <div
                    className={css.imageHolder}
                    style={{
                        backgroundImage: `url('https://horsedeal.imgix.net/static/careerPage/team-member-manissa-reichner.svg')`,
                    }}
                />
                <h3>Manissa Reichner</h3>
                <p>
                    Manissa ist nicht nur Influencerin, sondern bringt auch unseren Social Media
                    Auftritt zum Glänzen.
                </p>
            </div>
            <div className={css.gridItem}>
                <div
                    className={css.imageHolder}
                    style={{
                        backgroundImage: `url('https://horsedeal.imgix.net/static/careerPage/team-member-annina-steck.svg')`,
                    }}
                />
                <h3>Annina Steck</h3>
                <p>
                    Annina steht unseren Pferdebesitzern mit Rat und Tat zur Seite und hilft ihnen
                    beim erfolgreichen Inserieren.
                </p>
            </div>
            <div className={css.gridItem}>
                <div
                    className={css.imageHolder}
                    style={{
                        backgroundImage: `url('https://horsedeal.imgix.net/static/careerPage/team-member-celine-inglin.svg')`,
                    }}
                />
                <h3>Céline Inglin</h3>
                <p>
                    Céline ist Ansprechpartnerin für alle Reiter und unterstützt sie aktiv beim
                    Finden der passenden Reitbeteiligung.
                </p>
            </div>
        </main>

        <header>
            <h2 className={css.subHeading}>Standorte</h2>
            <p className={css.heading}>Unsere Workhubs</p>
            <p className={css.description}>
                {`Arbeiten wo Du möchtest und wann Du möchtest, das ist uns wichtig. Fast alle unsere Arbeiten \nkönnen auch im Home-Office erledigt werden. Für Meetings und Events stehen dir unsere \nWorkhubs jederzeit offen`}
            </p>
        </header>

        <aside>
            <div className={css.locationHolder}>
                <h3>Zürich</h3>
                <p>Coworking Space Kraftwerk · Selnaustrasse 25, 8001 Zürich</p>
                <div className={css.locationGrid}>
                    <div
                        style={{
                            backgroundImage: `url('https://horsedeal.imgix.net/static/careerPage/coworking-regus-zuerich-1.webp')`,
                        }}
                    />
                    <div
                        style={{
                            backgroundImage: `url('https://horsedeal.imgix.net/static/careerPage/coworking-regus-zuerich-2.webp')`,
                        }}
                    />
                    <div
                        style={{
                            backgroundImage: `url('https://horsedeal.imgix.net/static/careerPage/coworking-regus-zuerich-3.webp')`,
                        }}
                    />
                </div>
            </div>

            <div className={css.locationHolder}>
                <h3>Zug</h3>
                <p>Hauptsitz · Bösch 80a, 6331 Hünenberg</p>
                <div className={css.locationGrid}>
                    <div
                        style={{
                            backgroundImage: `url('https://horsedeal.imgix.net/static/careerPage/hauptsitz-zug-1.webp')`,
                        }}
                    />
                    <div
                        style={{
                            backgroundImage: `url('https://horsedeal.imgix.net/static/careerPage/hauptsitz-zug-2.webp')`,
                        }}
                    />
                    <div
                        style={{
                            backgroundImage: `url('https://horsedeal.imgix.net/static/careerPage/hauptsitz-zug-3.webp')`,
                        }}
                    />
                </div>
            </div>
        </aside>

        <header>
            <h2 className={css.subHeading}>Initiativbewerbung</h2>
            <p className={css.heading}>Nichts passendes dabei?</p>
            <p className={css.description}>
                {` Kein Ding! Schick uns gerne deine Initiativbewerbung und lass uns gemeinsam \nherausfinden, wie wir zusammenarbeiten können, um gemeinsam \nGrossartiges zu schaffen.`}
            </p>
        </header>

        <footer className={css.footer}>
            <a className={css.subLinkPrimary} href="/karriere">
                Initiativbewerbung senden
            </a>
        </footer>
    </section>
);
