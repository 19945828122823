import { getUserAddressInfo } from '../services/ipinfo';
import { exctractPublicDataCountry } from './location';

const LS_VERSIONING_KEY = '__version';
const CASH_TIME_IN_SECONDS = 2628000; // one month in seconds
const USER_LOCATION_LS_NAME = 'usr_loc';
const USER_SEARCH_PREDICTIONS = 'usr_srch_prdc';
const USER_SEARCH_PREDICTIONS_VERSION =
    process.env.REACT_APP_USER_SEARCH_PREDICTIONS_VERSION || '00.00.00';
const LAST_SEEN_LISTINGS = 'lst_sn_lstng';

export const ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT = 'ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT';
export const ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT_VERSION =
    process.env.REACT_APP_ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT_VERSION || '00.00.00';

export const TEMP_LISTING_STORAGE_KEY = 'TL0000';
export const TEMP_LISTING_LINK_KEY = 'TL0011';
export const TEMP_LISTING_SLUG_DATA_KEY = 'tempListingSlugData';

const LAST_SEEN_LISTINGS_MAX_NUM = 10;
const PREDICTIONS_MAX_NUM = 40;

const _localStorage = (() => {
    if (typeof window === 'object') {
        return window.localStorage;
    }
    // mock localStorage for ssr
    return {
        getItem: () => null,
        setItem: () => null,
        removeItem: () => null,
    };
})();

const getLSDataByKey = lsKey => {
    const lsFieldData = _localStorage.getItem(lsKey);

    try {
        if (lsFieldData) {
            return JSON.parse(lsFieldData);
        }
        throw new Error('');
    } catch (e) {
        return {};
    }
};

const setLSDataByKey = (lsKey, data) => _localStorage.setItem(lsKey, JSON.stringify(data));

export const removeTempListingData = () => _localStorage.removeItem(TEMP_LISTING_STORAGE_KEY);

export const removeTempListingLink = () => _localStorage.removeItem(TEMP_LISTING_LINK_KEY);

export const getTempListingData = () => getLSDataByKey(TEMP_LISTING_STORAGE_KEY);

export const getTempListingLink = () => getLSDataByKey(TEMP_LISTING_LINK_KEY);

export const getCurrentUserLocation = () => getLSDataByKey(USER_LOCATION_LS_NAME);

export const getLastSeenListings = () => getLSDataByKey(LAST_SEEN_LISTINGS);

export const setLastSeenListings = id => {
    const dataStored = getLastSeenListings();

    if (!Array.isArray(dataStored) || dataStored.length === 0) {
        return setLSDataByKey(LAST_SEEN_LISTINGS, [id]);
    }

    const idAlreadySet = dataStored.includes(id);

    const list = idAlreadySet
        ? /**
           * if a listing visited again, move it to the 1st place
           */
          [...dataStored.filter(storedId => storedId !== id), id]
        : [...dataStored, id];

    if (list.length > LAST_SEEN_LISTINGS_MAX_NUM) {
        list.shift();
    }

    setLSDataByKey(LAST_SEEN_LISTINGS, list);
};

export const setTempListingData = data =>
    _localStorage.setItem(
        TEMP_LISTING_LINK_KEY,
        JSON.stringify({
            [TEMP_LISTING_SLUG_DATA_KEY]: data,
        })
    );

export const setCurrentUserLocation = async store => {
    const userCurrentLocation = _localStorage.getItem(USER_LOCATION_LS_NAME);

    const { user } = store;
    const { currentUser } = user || {};

    const publicDataCountry = exctractPublicDataCountry(currentUser);

    if (userCurrentLocation) {
        try {
            const { createdAt, city, country, postal, countrySelected } = JSON.parse(
                userCurrentLocation
            );
            const timePastSinceLastUpd =
                (new Date().getTime() - new Date(createdAt).getTime()) / 1000;

            const userIsUnknown = !publicDataCountry;
            const userCountrySelected = userIsUnknown || publicDataCountry === countrySelected;
            const necessaryDataCollected = city && country && postal && userCountrySelected;

            const shouldRefetch = timePastSinceLastUpd >= CASH_TIME_IN_SECONDS;
            /** if data should be refetched, just do nothing,
             * otherwise throw error and return an user CL data
             */
            if (!shouldRefetch && necessaryDataCollected) {
                throw new Error({});
            }
        } catch (e) {
            console.log('Skip re-fetch user location info');
            return userCurrentLocation;
        }
    }

    try {
        const { error, message, ...locationData } = await getUserAddressInfo();

        if (error) {
            return _localStorage.removeItem(USER_LOCATION_LS_NAME);
        }

        _localStorage.setItem(
            USER_LOCATION_LS_NAME,
            /** countrySelected should be considered as the value with the highest priority,
             *  and it is set as soon as an info about user's location
             * is received from current user data at publicData field
             */
            JSON.stringify({
                ...locationData,
                createdAt: new Date(),
                countrySelected: publicDataCountry || null,
            })
        );

        return getCurrentUserLocation();
    } catch (e) {
        return {};
    }
};

export const updateCurrentUserLocationField = (key, value) => {
    const userCurrentLocation = _localStorage.getItem(USER_LOCATION_LS_NAME);
    if (userCurrentLocation) {
        try {
            const userData = JSON.parse(userCurrentLocation);
            if (userData.hasOwnProperty(key)) {
                userData[key] = value;
                _localStorage.setItem(USER_LOCATION_LS_NAME, JSON.stringify(userData));
            }
            return true;
        } catch (e) {
            return false;
        }
    }
    return false;
};

const increasePredictionNum = (userPredictionData, searchAsKey) => {
    try {
        userPredictionData[searchAsKey].useNum = userPredictionData[searchAsKey].useNum + 1;

        _localStorage.setItem(USER_SEARCH_PREDICTIONS, JSON.stringify(userPredictionData));
    } catch (e) {
        // do nothing
    }
};

const initiPredictionData = () => {
    _localStorage.setItem(
        USER_SEARCH_PREDICTIONS,
        JSON.stringify({
            [LS_VERSIONING_KEY]: USER_SEARCH_PREDICTIONS_VERSION,
        })
    );
};

export const checkUserPredictionData = searchAsKey => {
    const userPredictionJSON = _localStorage.getItem(USER_SEARCH_PREDICTIONS);

    if (!userPredictionJSON) {
        initiPredictionData();
        return undefined;
    }
    try {
        const userPredictionData = JSON.parse(userPredictionJSON);
        const data = userPredictionData[searchAsKey];
        const noVersioning = !userPredictionData[LS_VERSIONING_KEY];

        if (noVersioning) {
            initiPredictionData();
            throw new Error({});
        }

        if (
            userPredictionData[LS_VERSIONING_KEY] &&
            userPredictionData[LS_VERSIONING_KEY] !== USER_SEARCH_PREDICTIONS_VERSION
        ) {
            _localStorage.removeItem(USER_SEARCH_PREDICTIONS);
            throw new Error({});
        }

        if (!data) {
            throw new Error({});
        }

        increasePredictionNum(userPredictionData, searchAsKey);

        return data;
    } catch (e) {
        return undefined;
    }
};

export const setUserPredictionData = (searchAsKey, predictions) => {
    try {
        const userPredictionJSON = _localStorage.getItem(USER_SEARCH_PREDICTIONS);
        const userPredictionData = JSON.parse(userPredictionJSON);
        const userPredictionEntries = Object.entries(userPredictionData);
        const maxKeysNumExceeded = userPredictionEntries.length >= PREDICTIONS_MAX_NUM;

        if (maxKeysNumExceeded) {
            const { key } = userPredictionEntries.reduce(
                (acc, [key, item]) => {
                    if (key === LS_VERSIONING_KEY) return acc;

                    if (item.useNum < acc.useNum) {
                        acc = { key, ...item };
                    }
                    return acc;
                },
                { key: 'null', useNum: Infinity }
            );
            // delete the least used key
            delete userPredictionData[key];
        }

        const data = predictions.map(({ place_id, distance, predictionPlace, description }) => ({
            place_id,
            distance,
            predictionPlace,
            description,
        }));

        const userPredictionDataUpdated = {
            ...userPredictionData,
            [searchAsKey]: { data, useNum: 1 },
        };

        _localStorage.setItem(USER_SEARCH_PREDICTIONS, JSON.stringify(userPredictionDataUpdated));

        return true;
    } catch (e) {
        return false;
    }
};

export const getOneSignalPromptData = () => {
    try {
        const data = getLSDataByKey(ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT);
        if (!data.accepted || !data[LS_VERSIONING_KEY]) {
            throw new Error({});
        }

        const versioning = data[LS_VERSIONING_KEY];

        if (versioning !== ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT_VERSION) {
            _localStorage.removeItem(ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT);
            throw new Error({});
        }

        return data;
    } catch (e) {
        return undefined;
    }
};

export const setOneSignalPromptData = () => {
    const data = {
        accepted: true,
        [LS_VERSIONING_KEY]: ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT_VERSION,
    };

    setLSDataByKey(ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT, data);
};
