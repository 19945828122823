import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { isTransactionsTransitionInvalidTransition, storableError } from '../../util/errors';
import {
    getReviewCurrentTransition,
    txIsInFirstReviewBy,
    TRANSITION_ENQUIRY_ACCEPT,
    TRANSITION_ENQUIRY_ACCEPT_OWNER,
    TRANSITION_ENQUIRY_DECLINE,
    TRANSITION_ENQUIRY_DECLINE_OWNER,
    FILE_MESSAGE_TEXT_CONTENT,
    APPOINTMENT_DECLINED_STATUS,
    APPOINTMENT_CANCELED_STATUS,
    APPOINTMENT_ACCEPTED_STATUS,
    DECLINE_APPOINTMENT_REQUEST,
    CANCEL_APPOINTMENT_REQUEST,
    ACCEPT_APPOINTMENT_REQUEST,
    TX_TRANSITION_ACTOR_CUSTOMER,
    TX_TRANSITION_ACTOR_PROVIDER,
} from '../../util/transaction';

import { denormalisedResponseEntities } from '../../util/data';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
    fetchCurrentUser,
    markUnreadMessageAsViewed,
    updateUserProfileInfo,
} from '../../ducks/user.duck';
import { loadData as inboxLoadData } from '../InboxPage/InboxPage.duck';
import { sendSGEmail, withdrawUserCredits } from '../../util/api';

const { UUID } = sdkTypes;
const { canonicalRootURL } = config;
export const CREDITS_NUM_TO_UNLOCK_PAGE = 30;
export const CHAT_ENTITY_TYPE_MESSAGE = 'message';
export const CHAT_ENTITY_TYPE_TRANSITION = 'transition';
export const CHAT_ENTITY_TYPE_APPOINTMENT = 'appointment';

const MESSAGES_PAGE_SIZE = 100;
const CUSTOMER = 'customer';

const REVIEW_TX_INCLUDES = ['reviews', 'reviews.author', 'reviews.subject'];
const IMAGE_VARIANTS = {
    'fields.image': [
        // Profile images
        'variants.square-small',
        'variants.square-small2x',

        // Listing images:
        'variants.landscape-crop',
        'variants.landscape-crop2x',
    ],
};
// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/TransactionPage/SET_INITIAL_VALUES';

export const FETCH_TRANSACTION_REQUEST = 'app/TransactionPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/TransactionPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/TransactionPage/FETCH_TRANSACTION_ERROR';

export const FETCH_TRANSITIONS_REQUEST = 'app/TransactionPage/FETCH_TRANSITIONS_REQUEST';
export const FETCH_TRANSITIONS_SUCCESS = 'app/TransactionPage/FETCH_TRANSITIONS_SUCCESS';
export const FETCH_TRANSITIONS_ERROR = 'app/TransactionPage/FETCH_TRANSITIONS_ERROR';

export const ACCEPT_SALE_REQUEST = 'app/TransactionPage/ACCEPT_SALE_REQUEST';
export const ACCEPT_SALE_SUCCESS = 'app/TransactionPage/ACCEPT_SALE_SUCCESS';
export const ACCEPT_SALE_ERROR = 'app/TransactionPage/ACCEPT_SALE_ERROR';

export const DECLINE_SALE_REQUEST = 'app/TransactionPage/DECLINE_SALE_REQUEST';
export const DECLINE_SALE_SUCCESS = 'app/TransactionPage/DECLINE_SALE_SUCCESS';
export const DECLINE_SALE_ERROR = 'app/TransactionPage/DECLINE_SALE_ERROR';

export const FETCH_MESSAGES_REQUEST = 'app/TransactionPage/FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'app/TransactionPage/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'app/TransactionPage/FETCH_MESSAGES_ERROR';

export const SEND_MESSAGE_REQUEST = 'app/TransactionPage/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'app/TransactionPage/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'app/TransactionPage/SEND_MESSAGE_ERROR';

export const SEND_FILE_REQUEST = 'app/TransactionPage/SEND_FILE_REQUEST';
export const SEND_FILE_SUCCESS = 'app/TransactionPage/SEND_FILE_SUCCESS';
export const SEND_FILE_ERROR = 'app/TransactionPage/SEND_FILE_ERROR';

export const SEND_REVIEW_REQUEST = 'app/TransactionPage/SEND_REVIEW_REQUEST';
export const SEND_REVIEW_SUCCESS = 'app/TransactionPage/SEND_REVIEW_SUCCESS';
export const SEND_REVIEW_ERROR = 'app/TransactionPage/SEND_REVIEW_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/TransactionPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/TransactionPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/TransactionPage/FETCH_TIME_SLOTS_ERROR';

export const HANDLE_INQUIRY_REQUEST = 'app/TransactionPage/HANDLE_INQUIRY_REQUEST';
export const HANDLE_INQUIRY_SUCCESS = 'app/TransactionPage/HANDLE_INQUIRY_SUCCESS';
export const HANDLE_INQUIRY_ACCEPT = 'app/TransactionPage/HANDLE_INQUIRY_ACCEPT';
export const HANDLE_INQUIRY_ERROR = 'app/TransactionPage/HANDLE_INQUIRY_ERROR';

export const SEND_APPOINTMENT_REQUEST = 'app/TransactionPage/SEND_APPOINTMENT_REQUEST';
export const SEND_APPOINTMENT_SUCCESS = 'app/TransactionPage/SEND_APPOINTMENT_SUCCESS';
export const SEND_APPOINTMENT_UPDATE_SUCCESS =
    'app/TransactionPage/SEND_APPOINTMENT_UPDATE_SUCCESS';
export const SET_APPOINTMENT_DATA_TO_BE_FILLED =
    'app/TransactionPage/SET_APPOINTMENT_DATA_TO_BE_FILLED';

export const SEND_APPOINTMENT_ERROR = 'app/TransactionPage/SEND_APPOINTMENT_ERROR';
export const SET_APPOINTMENT_FETCH_ERROR = 'app/TransactionPage/SET_APPOINTMENT_FETCH_ERROR';
export const SET_APPOINTMENT_FETCH_SUCCESS = 'app/TransactionPage/SET_APPOINTMENT_FETCH_SUCCESS';
export const SET_APPOINTMENT_FETCH_IN_PROGRESS =
    'app/TransactionPage/SET_APPOINTMENT_FETCH_IN_PROGRESS';
export const ADD_APPOINTMENT_ENTITY = 'app/TransactionPage/ADD_APPOINTMENT_ENTITY';
export const MERGE_APPOINTMENT_ENTITY = 'app/TransactionPage/MERGE_APPOINTMENT_ENTITY';
export const INIT_APPOINTMENT_ENTITIES = 'app/TransactionPage/INIT_APPOINTMENT_ENTITIES';

export const PROVIDE_CONSULT_CHAT_REQUEST_MIXPANEL =
    'app/TransactionPage/PROVIDE_CONSULT_CHAT_REQUEST_MIXPANEL';
export const PROVIDE_ANSWER_CHAT_REQUEST_MIXPANEL =
    'app/TransactionPage/PROVIDE_ANSWER_CHAT_REQUEST_MIXPANEL';

// ================ Reducer ================ //

const initialState = {
    fetchMessagesError: null,
    totalMessages: 0,
    totalMessagePages: 0,
    oldestMessagePageFetched: 0,
    messages: [],
    initialMessageFailedToTransaction: null,
    savePaymentMethodFailed: false,
    sendMessageError: null,
    sendFileError: null,
    sendReviewError: null,
    timeSlots: null,
    fetchTimeSlotsError: null,
    fetchTransitionsError: null,
    processTransitions: null,
    appointmentError: null,
    appointmentFetchError: null,
    appointmentFetchInProgress: false,
    appointmentEntities: {},
    // subscriptionError: null,
    inquiryError: null,
    fetchMessagesInProgress: false,
    // fetchTransactionInProgress: false,
    transactionRequestInProgress: false,
    sendMessageInProgress: false,
    sendFileInProgress: false,
    sendAppointmentInProgress: false,
    sendReviewInProgress: false,
};

// Merge entity arrays using ids, so that conflicting items in newer array (b) overwrite old values (a).
// const a = [{ id: { uuid: 1 } }, { id: { uuid: 3 } }];
// const b = [{ id: : { uuid: 2 } }, { id: : { uuid: 1 } }];
// mergeEntityArrays(a, b)
// => [{ id: { uuid: 3 } }, { id: : { uuid: 2 } }, { id: : { uuid: 1 } }]
const mergeEntityArrays = (a, b) => {
    return a.filter(aEntity => !b.find(bEntity => aEntity.id.uuid === bEntity.id.uuid)).concat(b);
};

export default function checkoutPageReducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case SET_INITAL_VALUES:
            return { ...initialState, ...payload };

        case FETCH_TRANSACTION_REQUEST:
            return { ...state, fetchTransactionInProgress: true, fetchTransactionError: null };
        case FETCH_TRANSACTION_SUCCESS: {
            const transactionRef = { id: payload.id, type: 'transaction' };
            return { ...state, fetchTransactionInProgress: false, transactionRef };
        }
        case FETCH_TRANSACTION_ERROR:
            return { ...state, fetchTransactionInProgress: false, fetchTransactionError: payload };

        case FETCH_TRANSITIONS_REQUEST:
            return { ...state, fetchTransitionsInProgress: true, fetchTransitionsError: null };
        case FETCH_TRANSITIONS_SUCCESS:
            return { ...state, fetchTransitionsInProgress: false, processTransitions: payload };
        case FETCH_TRANSITIONS_ERROR:
            return { ...state, fetchTransitionsInProgress: false, fetchTransitionsError: payload };

        case HANDLE_INQUIRY_REQUEST:
            return { ...state, transactionRequestInProgress: true, inquiryError: null };
        case HANDLE_INQUIRY_SUCCESS:
            return { ...state, transactionRequestInProgress: false };
        case HANDLE_INQUIRY_ERROR:
            return { ...state, transactionRequestInProgress: false, inquiryError: payload };

        case FETCH_MESSAGES_REQUEST:
            return {
                ...state,
                messages: [],
                totalMessages: 0,
                totalMessagePages: 0,
                oldestMessagePageFetched: 0,
                fetchMessagesInProgress: true,
                fetchMessagesError: null,
            };
        case FETCH_MESSAGES_SUCCESS: {
            const oldestMessagePageFetched =
                state.oldestMessagePageFetched > payload.page
                    ? state.oldestMessagePageFetched
                    : payload.page;
            return {
                ...state,
                fetchMessagesInProgress: false,
                messages: mergeEntityArrays(state.messages, payload.messages),
                totalMessages: payload.totalItems,
                totalMessagePages: payload.totalPages,
                oldestMessagePageFetched,
            };
        }
        case FETCH_MESSAGES_ERROR:
            return { ...state, fetchMessagesInProgress: false, fetchMessagesError: payload };

        case SEND_MESSAGE_REQUEST:
            return {
                ...state,
                sendMessageInProgress: true,
                sendMessageError: null,
                initialMessageFailedToTransaction: null,
            };
        case SEND_MESSAGE_SUCCESS:
            return { ...state, sendMessageInProgress: false };
        case SEND_MESSAGE_ERROR:
            return { ...state, sendMessageInProgress: false, sendMessageError: payload };

        case SEND_FILE_REQUEST:
            return {
                ...state,
                sendFileInProgress: true,
                sendFileError: null,
            };
        case SEND_FILE_SUCCESS:
            return { ...state, sendFileInProgress: false };
        case SEND_FILE_ERROR:
            return { ...state, sendFileInProgress: false, sendFileError: payload };

        case SEND_REVIEW_REQUEST:
            return { ...state, sendReviewInProgress: true, sendReviewError: null };
        case SEND_REVIEW_SUCCESS:
            return { ...state, sendReviewInProgress: false };
        case SEND_REVIEW_ERROR:
            return { ...state, sendReviewInProgress: false, sendReviewError: payload };

        case SEND_APPOINTMENT_REQUEST:
            return { ...state, sendAppointmentInProgress: true, appointmentError: null };
        case SEND_APPOINTMENT_SUCCESS:
            return { ...state, sendAppointmentInProgress: false, appointmentError: null };
        case SEND_APPOINTMENT_UPDATE_SUCCESS:
            return { ...state, sendAppointmentInProgress: false, appointmentError: null };
        case SEND_APPOINTMENT_ERROR:
            return { ...state, sendAppointmentInProgress: false, appointmentError: payload };
        case SET_APPOINTMENT_FETCH_ERROR:
            return { ...state, appointmentFetchError: payload };
        case SET_APPOINTMENT_FETCH_IN_PROGRESS:
            return { ...state, appointmentFetchError: null, appointmentFetchInProgress: true };
        case SET_APPOINTMENT_FETCH_SUCCESS:
            return { ...state, appointmentFetchError: null, appointmentFetchInProgress: false };

        case ADD_APPOINTMENT_ENTITY:
            return {
                ...state,
                appointmentEntities: {
                    ...state.appointmentEntities,
                    [payload.transactionId]: [
                        ...(state.appointmentEntities[payload.transactionId] || []),
                        payload.entity,
                    ],
                },
            };
        case MERGE_APPOINTMENT_ENTITY:
            return {
                ...state,
                appointmentEntities: {
                    ...state.appointmentEntities,
                    [payload.transactionId]: [
                        ...(state.appointmentEntities[payload.transactionId] || []).map(entity =>
                            entity._id === payload._id ? payload : entity
                        ),
                    ],
                },
            };
        case INIT_APPOINTMENT_ENTITIES:
            return {
                ...state,
                appointmentEntities: {
                    ...state.appointmentEntities,
                    [payload.transactionId]: [...payload.entities],
                },
            };

        case FETCH_TIME_SLOTS_REQUEST:
            return { ...state, fetchTimeSlotsError: null };
        case FETCH_TIME_SLOTS_SUCCESS:
            return { ...state, timeSlots: payload };
        case FETCH_TIME_SLOTS_ERROR:
            return { ...state, fetchTimeSlotsError: payload };

        default:
            return state;
    }
}

// ================ Selectors ================ //

export const acceptOrDeclineInProgress = state => {
    return state.TransactionPage.transactionRequestInProgress;
};

// ================ Action creators ================ //
export const setInitialValues = initialValues => ({
    type: SET_INITAL_VALUES,
    payload: pick(initialValues, Object.keys(initialState)),
});

// const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = response => ({
    type: FETCH_TRANSACTION_SUCCESS,
    payload: response,
});
const fetchTransitionsRequest = () => ({ type: FETCH_TRANSITIONS_REQUEST });
const fetchTransitionsSuccess = response => ({
    type: FETCH_TRANSITIONS_SUCCESS,
    payload: response,
});
const fetchTransitionsError = e => ({ type: FETCH_TRANSITIONS_ERROR, error: true, payload: e });

const fetchMessagesRequest = () => ({ type: FETCH_MESSAGES_REQUEST });
const fetchMessagesSuccess = (messages, pagination) => ({
    type: FETCH_MESSAGES_SUCCESS,
    payload: { messages, ...pagination },
});
const fetchMessagesError = e => ({ type: FETCH_MESSAGES_ERROR, error: true, payload: e });

const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST });
const sendMessageSuccess = txId => ({ type: SEND_MESSAGE_SUCCESS, payload: txId });
const sendMessageError = e => ({ type: SEND_MESSAGE_ERROR, error: true, payload: e });

const sendFileRequest = () => ({ type: SEND_FILE_REQUEST });
const sendFileSuccess = txId => ({ type: SEND_FILE_SUCCESS, payload: txId });
const sendFileError = e => ({ type: SEND_FILE_ERROR, error: true, payload: e });

const sendReviewRequest = () => ({ type: SEND_REVIEW_REQUEST });
const sendReviewSuccess = txId => ({ type: SEND_REVIEW_SUCCESS, payload: txId });
const sendReviewError = e => ({ type: SEND_REVIEW_ERROR, error: true, payload: e });

const sendAppointmentRequest = () => ({ type: SEND_APPOINTMENT_REQUEST });
const sendAppointmentSuccess = txId => ({ type: SEND_APPOINTMENT_SUCCESS, payload: txId });
const updateAppointmentSuccess = txId => ({ type: SEND_APPOINTMENT_UPDATE_SUCCESS, payload: txId });
const sendAppointmentError = error => ({ type: SEND_APPOINTMENT_ERROR, payload: error });

const setAppointmentFetchSuccess = () => ({ type: SET_APPOINTMENT_FETCH_SUCCESS });
const setAppointmentFetchInProgress = () => ({ type: SET_APPOINTMENT_FETCH_IN_PROGRESS });
const setAppointmentFetchError = error => ({ type: SET_APPOINTMENT_FETCH_ERROR, payload: error });

const addAppointmentEntity = payload => ({ type: ADD_APPOINTMENT_ENTITY, payload });
const mergeAppointmentEntity = payload => ({ type: MERGE_APPOINTMENT_ENTITY, payload });
const initAppointmentEntities = payload => ({ type: INIT_APPOINTMENT_ENTITIES, payload });

const inquiryHandlingInProgress = () => ({ type: HANDLE_INQUIRY_REQUEST });
const inquiryHandlingSuccess = txId => ({ type: HANDLE_INQUIRY_SUCCESS, payload: txId });
const inquiryHandlingAccept = () => ({ type: HANDLE_INQUIRY_ACCEPT });
const inquiryHandlingError = e => ({ type: HANDLE_INQUIRY_ERROR, payload: e });

export const consultChatRequestMixpanel = txId => ({
    type: PROVIDE_CONSULT_CHAT_REQUEST_MIXPANEL,
    payload: { txId },
});
const answerChatRequestMixpanel = payload => ({
    type: PROVIDE_ANSWER_CHAT_REQUEST_MIXPANEL,
    payload,
});

// const subscriptionHandlingInProgress = () => ({ type: HANDLE_SUBSCRIPTION_IN_PROGRESS });
// const subscriptionHandlingSuccess = txId => ({ type: HANDLE_SUBSCRIPTION_SUCCESS, payload: txId });
// const subscriptionAccepted = () => ({ type: SUBSCRIPTION_ACCEPTED });
// const subscriptionRequested = () => ({ type: SUBSCRIPTION_REQUESTED });
// const subscriptionHandlingError = e => ({ type: HANDLE_SUBSCRIPTION_ERROR, payload: e });

const fetchTimeSlotsRequest = () => ({ type: FETCH_TIME_SLOTS_REQUEST });
const fetchTimeSlotsSuccess = timeSlots => ({
    type: FETCH_TIME_SLOTS_SUCCESS,
    payload: timeSlots,
});
const fetchTimeSlotsError = e => ({
    type: FETCH_TIME_SLOTS_ERROR,
    error: true,
    payload: e,
});

// ================ Thunks ================ //

// export const submitSubscriptionRequest = txData => (dispatch, getState, sdk) => {
//     if (acceptOrDeclineInProgress(getState())) {
//         return Promise.reject(new Error('Accept or decline already in progress'));
//     }

//     const {
//         transaction,
//         booking,
//         bookingData,
//         coupon,
//         paymentMethodId = null,
//         selectedPaymentGateway,
//     } = txData;
//     const { uuid: id } = transaction.id;

//     dispatch(subscriptionHandlingInProgress());

//     return sdk.transactions
//         .transition(
//             {
//                 id,
//                 transition: TRANSITION_REQUEST_SUBSCRIPTION_AFTER_ENQUIRY,
//                 params: {
//                     protectedData: {
//                         booking: JSON.stringify(booking),
//                         bookingData: JSON.stringify(bookingData),
//                         defaultPaymentMethod: paymentMethodId,
//                         coupon,
//                         selectedPaymentGateway,
//                     },
//                 },
//             },
//             { expand: true }
//         )
//         .then(response => {
//             dispatch(addMarketplaceEntities(response));
//             dispatch(subscriptionHandlingSuccess({ uuid: id }));
//             dispatch(subscriptionRequested());
//             return response;
//         })
//         .catch(error => {
//             dispatch(subscriptionHandlingError(error));
//             throw error;
//         });
// };

// export const acceptSale = (transaction, providerData, listingId) => (dispatch, getState, sdk) => {
//     if (acceptOrDeclineInProgress(getState())) {
//         return Promise.reject(new Error('Accept or decline already in progress'));
//     }

//     const { id } = transaction;
//     const { uuid: txUuid } = id;
//     const { uuid: listingUuid } = listingId;
//     const { protectedData } = transaction.attributes;
//     /** selectedPaymentGateway may be bank card, bank transfer or invoice payment */
//     const { coupon, defaultPaymentMethod } = protectedData || {};
//     const body = {};

//     if (coupon) {
//         const { id } = parseJSONInfo(coupon) || {};
//         body.coupon = id;
//     }
//     if (defaultPaymentMethod) {
//         body.defaultPaymentMethod = defaultPaymentMethod;
//     }

//     const params =
//         coupon || defaultPaymentMethod
//             ? {
//                   method: 'POST',
//                   headers: {
//                       'Content-Type': 'application/json',
//                   },
//                   body: JSON.stringify(body),
//               }
//             : { method: 'POST' };

// dispatch(subscriptionHandlingInProgress());

// return fetch(`/api/subscriptions/listing/${listingUuid}/${txUuid}/subscribe`, params)
//     .then(async response => {
//         const { status } = response;
//         const body = await response.json();

//         if (status !== 201 && status !== 200) {
//             throw new Error(body.message || body.error || body);
//         }
//         return body;
//     })
//     .then(() => {
//         return sdk.transactions
//             .transition(
//                 {
//                     id,
//                     transition: TRANSITION_ACTIVATE_SUBSCRIPTION,
//                     params: {
//                         protectedData: {
//                             providerData: JSON.stringify(providerData),
//                         },
//                     },
//                 },
//                 { expand: true }
//             )
//             .then(response => {
//                 dispatch(addMarketplaceEntities(response));
//                 dispatch(subscriptionHandlingSuccess({ uuid: txUuid }));
//                 dispatch(subscriptionAccepted());
//                 dispatch(getAcceptedAndActiveTransactionsData());
//                 return response;
//             })
//             .catch(e => {
//                 dispatch(subscriptionHandlingError(storableError(e)));
//                 log.error(e, 'accept-sale-failed', {
//                     id,
//                     transition: TRANSITION_ACTIVATE_SUBSCRIPTION,
//                 });
//                 throw e;
//             });
//     })
//     .catch(error => {
//         dispatch(subscriptionHandlingError(error.message || error));
//         console.error('Subscription failed ', error);
//     });
// };

// export const declineSale = ({ id }) => (dispatch, getState, sdk) => {
//     if (acceptOrDeclineInProgress(getState())) {
//         return Promise.reject(new Error('Accept or decline already in progress'));
//     }

//     dispatch(subscriptionHandlingInProgress());

//     return sdk.transactions
//         .transition(
//             { id, transition: TRANSITION_DECLINE_SUBSCRIPTION, params: {} },
//             { expand: true }
//         )
//         .then(response => {
//             dispatch(addMarketplaceEntities(response));
//             dispatch(subscriptionHandlingSuccess({ uuid: id.uuid || id }));
//             return response;
//         })
//         .catch(e => {
//             dispatch(subscriptionHandlingError(storableError(e)));
//             log.error(e, 'reject-sale-failed', {
//                 txId: id,
//                 transition: TRANSITION_DECLINE_SUBSCRIPTION,
//             });
//             throw e;
//         });
// };

export const fetchFiles = (txId, messages) => async dispatch => {
    try {
        const { uuid } = txId;
        const filesJson = await fetch(`/api/transactions/${uuid}/messages`);

        const filesData = await filesJson.json();
        const files = (filesData && filesData.data && filesData.data.data) || null;

        if (files && messages) {
            messages.forEach(s => {
                const id = s.id.uuid;
                const fileHolder = files.filter(f => f.id.uuid === id)[0];
                if (fileHolder && fileHolder.attributes && fileHolder.attributes.file) {
                    s.attributes.file = fileHolder.attributes.file;
                }
                return s;
            });
        }
    } catch (error) {
        console.error('The following error occured at fetchMessages method: ', error);
    }
};

const fetchMessages = (txId, page) => (dispatch, getState, sdk) => {
    const paging = { page, per_page: MESSAGES_PAGE_SIZE };
    dispatch(fetchMessagesRequest());

    return sdk.messages
        .query({
            transaction_id: txId,
            include: ['sender', 'sender.profileImage'],
            ...IMAGE_VARIANTS,
            ...paging,
        })
        .then(async response => {
            const messages = denormalisedResponseEntities(response);

            await dispatch(fetchFiles(txId, messages));
            // await dispatch(fetchAppointments(txId, messages));

            const { totalItems, totalPages, page: fetchedPage } = response.data.meta;
            const pagination = { totalItems, totalPages, page: fetchedPage };
            const totalMessages = getState().TransactionPage.totalMessages;

            // Original fetchMessages call succeeded
            dispatch(fetchMessagesSuccess(messages, pagination));

            // Check if totalItems has changed between fetched pagination pages
            // if totalItems has changed, fetch first page again to include new incoming messages.
            // TODO if there're more than 100 incoming messages,
            // this should loop through most recent pages instead of fetching just the first one.
            if (totalItems > totalMessages && page > 1) {
                dispatch(fetchMessages(txId, 1))
                    .then(() => {
                        // Original fetch was enough as a response for user action,
                        // this just includes new incoming messages
                    })
                    .catch(() => {
                        // Background update, no need to to do anything atm.
                    });
            }
        })
        .catch(e => {
            dispatch(fetchMessagesError(storableError(e)));
            throw e;
        });
};

export const fetchMoreMessages = txId => (dispatch, getState, sdk) => {
    const state = getState();
    const { oldestMessagePageFetched, totalMessagePages } = state.TransactionPage;
    const hasMoreOldMessages = totalMessagePages > oldestMessagePageFetched;

    // In case there're no more old pages left we default to fetching the current cursor position
    const nextPage = hasMoreOldMessages ? oldestMessagePageFetched + 1 : oldestMessagePageFetched;

    return dispatch(fetchMessages(txId, nextPage));
};

export const sendMessage = (txId, message) => (dispatch, getState, sdk) => {
    dispatch(sendMessageRequest());

    return sdk.messages
        .send({ transactionId: txId, content: message })
        .then(response => {
            const messageId = response.data.data.id;

            // We fetch the first page again to add sent message to the page data
            // and update possible incoming messages too.
            // TODO if there're more than 100 incoming messages,
            // this should loop through most recent pages instead of fetching just the first one.
            return dispatch(fetchMessages(txId, 1))
                .then(() => {
                    dispatch(sendMessageSuccess(txId));
                    return messageId;
                })
                .catch(() => dispatch(sendMessageSuccess(txId)));
        })
        .catch(e => {
            dispatch(sendMessageError(storableError(e)));
            // Rethrow so the page can track whether the sending failed, and
            // keep the message in the form for a retry.
            throw e;
        });
};

export const sendFile = (transaction, file) => (dispatch, getState, sdk) => {
    const transactionUuid = transaction.id.uuid;

    const formData = new FormData();

    formData.append('file', file);
    formData.append('content', FILE_MESSAGE_TEXT_CONTENT);

    dispatch(sendFileRequest());

    return fetch(`/api/transactions/${transactionUuid}/messages?page=1`, {
        method: 'POST',
        body: formData,
    })
        .then(res => res.json())
        .then(res => {
            const { status, message } = res;

            if (status !== 200) {
                throw new Error(message || 'Failed to send file.');
            }

            return dispatch(fetchMessages(transaction.id, 1)).then(() => {
                dispatch(sendFileSuccess(transaction.id));
                return res;
            });
        })
        .catch(error => {
            error.type = 'error';
            dispatch(sendFileError(error));
            dispatch(sendMessageError(storableError(error)));
        });
};

// const unlockTransaction = async body => {
//     try {
//         const res = await fetch('/api/transaction/initiate-unlocking', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(body),
//         });
//         if (res.status !== 200) {
//             throw new Error('Unable to send data');
//         }
//         return {};
//     } catch (error) {
//         return { error: error.message };
//     }
// };

export const respondOnCustomerInquiry = (txData, { declineMessage, reason }) => async (
    dispatch,
    getState,
    sdk
) => {
    const {
        user: { currentUser },
    } = getState();
    const { txId, otherParty, currentListing } = txData;
    const {
        attributes: {
            profile: {
                publicData: { txRole, userType: otherPartyUserType },
            },
        },
    } = otherParty;

    dispatch(inquiryHandlingInProgress());

    const isDeclineAction = declineMessage && reason;

    const transitionDictionary = {
        [TX_TRANSITION_ACTOR_PROVIDER]: {
            accept_transition: TRANSITION_ENQUIRY_ACCEPT_OWNER,
            decline_transition: TRANSITION_ENQUIRY_DECLINE_OWNER,
        },
        [TX_TRANSITION_ACTOR_CUSTOMER]: {
            accept_transition: TRANSITION_ENQUIRY_ACCEPT,
            decline_transition: TRANSITION_ENQUIRY_DECLINE,
        },
    };

    const { accept_transition, decline_transition } = transitionDictionary[txRole];

    const transition = isDeclineAction ? decline_transition : accept_transition;
    const shouldWithdrawCredits =
        transition !== TRANSITION_ENQUIRY_DECLINE &&
        transition !== TRANSITION_ENQUIRY_DECLINE_OWNER;

    try {
        if (shouldWithdrawCredits) {
            const response = await withdrawUserCredits(
                currentUser.id.uuid,
                CREDITS_NUM_TO_UNLOCK_PAGE
            );
            const data = await response.json();
            const { error, message } = data;

            if (error) {
                throw new Error(message);
            }

            dispatch(fetchCurrentUser());
        }

        // await unlockTransaction({ transactionId: txId.uuid, email, firstName, lastName });
    } catch (e) {
        return dispatch(inquiryHandlingError(e));
    }

    const params = isDeclineAction
        ? {
              protectedData: {
                  isDeclined: true,
                  reason,
              },
          }
        : {};

    return sdk.transactions
        .transition(
            {
                id: txId.uuid,
                transition,
                params,
            },
            { expand: true }
        )
        .then(response => {
            dispatch(answerChatRequestMixpanel({ txId, accepted: !isDeclineAction }));
            dispatch(addMarketplaceEntities(response));

            if (declineMessage) {
                dispatch(sendMessage(txId, declineMessage));

                const emailTemplate =
                    otherPartyUserType === 'rider'
                        ? 'transactionDeclinedRider'
                        : 'transactionDeclinedOwner';

                sendSGEmail({
                    recipientId: otherParty.id.uuid,
                    emailTemplate,
                    groupIdName: 'userTransactionChatsGroupId',
                    data: {
                        recipientFirstName: otherParty.attributes.profile.firstName,
                        otherPartyName: currentUser.attributes.profile.firstName,
                        listingTitle: currentListing.attributes.title,
                        chatPageUrl: window.location.href,
                        canonicalRootURL: canonicalRootURL,
                        riderProfilePage: `${canonicalRootURL}/profile/rider/${otherParty.id.uuid}`,
                    },
                });
            } else {
                dispatch(inquiryHandlingAccept());

                const emailTemplate =
                    otherPartyUserType === 'rider'
                        ? 'transactionAcceptedRider'
                        : 'transactionAcceptedHorseowner';

                sendSGEmail({
                    recipientId: otherParty.id.uuid,
                    emailTemplate,
                    groupIdName: 'userTransactionChatsGroupId',
                    data: {
                        recipientFirstName: otherParty.attributes.profile.firstName,
                        otherPartyName: currentUser.attributes.profile.firstName,
                        listingTitle: currentListing.attributes.title,
                        chatPageUrl: window.location.href,
                        canonicalRootURL: canonicalRootURL,
                    },
                });
            }

            dispatch(inquiryHandlingSuccess(txId));

            return response;
        })
        .catch(error => {
            const isSdkError = error && error.data && Array.isArray(error.data.errors);
            if (isSdkError) {
                return dispatch(inquiryHandlingError(error.data.errors[0].title || error));
            }
            dispatch(inquiryHandlingError(error));
        });
};

// If other party has already sent a review, we need to make transition to
// TRANSITION_REVIEW_2_BY_<CUSTOMER/PROVIDER>
const sendReviewAsSecond = (tx, params, role, dispatch, sdk) => {
    const { id, attributes } = tx;
    const { lastTransition } = attributes;
    const transition = getReviewCurrentTransition(role === CUSTOMER, lastTransition);

    const include = REVIEW_TX_INCLUDES;

    return sdk.transactions
        .transition({ id, transition, params }, { expand: true, include, ...IMAGE_VARIANTS })
        .then(response => {
            dispatch(addMarketplaceEntities(response));
            dispatch(sendReviewSuccess(id));
            return response;
        })
        .catch(e => {
            dispatch(sendReviewError(storableError(e)));

            // Rethrow so the page can track whether the sending failed, and
            // keep the message in the form for a retry.
            throw e;
        });
};

// If other party has not yet sent a review, we need to make transition to
// TRANSITION_REVIEW_1_BY_<CUSTOMER/PROVIDER>
// However, the other party might have made the review after previous data synch point.
// So, error is likely to happen and then we must try another state transition
// by calling sendReviewAsSecond().
const sendReviewAsFirst = (tx, params, role, dispatch, sdk) => {
    const { id, attributes } = tx;
    const { lastTransition } = attributes;
    const transition = getReviewCurrentTransition(role === CUSTOMER, lastTransition);
    const include = REVIEW_TX_INCLUDES;

    return sdk.transactions
        .transition({ id, transition, params }, { expand: true, include, ...IMAGE_VARIANTS })
        .then(response => {
            dispatch(addMarketplaceEntities(response));
            dispatch(sendReviewSuccess(id));
            return response;
        })
        .catch(e => {
            // If transaction transition is invalid, lets try another endpoint.
            if (isTransactionsTransitionInvalidTransition(e)) {
                return sendReviewAsSecond(tx, params, role, dispatch, sdk);
            } else {
                dispatch(sendReviewError(storableError(e)));

                // Rethrow so the page can track whether the sending failed, and
                // keep the message in the form for a retry.
                throw e;
            }
        });
};

export const sendReview = (role, tx, reviewRating, reviewContent) => (dispatch, getState, sdk) => {
    const params = { reviewRating, reviewContent };

    const txStateOtherPartyFirst = txIsInFirstReviewBy(tx, role !== CUSTOMER);

    dispatch(sendReviewRequest());

    return txStateOtherPartyFirst
        ? sendReviewAsSecond(tx, params, role, dispatch, sdk)
        : sendReviewAsFirst(tx, params, role, dispatch, sdk);
};

const isNonEmpty = value => {
    return typeof value === 'object' || Array.isArray(value) ? !isEmpty(value) : !!value;
};

export const fetchNextTransitions = id => (dispatch, getState, sdk) => {
    dispatch(fetchTransitionsRequest());

    return sdk.processTransitions
        .query({ transactionId: id })
        .then(res => {
            dispatch(fetchTransitionsSuccess(res.data.data));
        })
        .catch(e => {
            dispatch(fetchTransitionsError(storableError(e)));
        });
};

export const fetchAppointments = transactions => dispatch => {
    dispatch(setAppointmentFetchInProgress());

    return Promise.all(
        transactions.map(async ({ id: { uuid } }) => {
            const response = await fetch(`/api/appointment/${uuid}`);
            const { error, message, appointments, transitions } = await response.json();

            if (error) {
                return dispatch(setAppointmentFetchError(message));
            }

            dispatch(
                initAppointmentEntities({
                    transactionId: uuid,
                    entities: [...appointments, ...transitions],
                })
            );
        })
    )
        .then(() => dispatch(setAppointmentFetchSuccess()))
        .catch(error => dispatch(setAppointmentFetchError(error.message)));
};

const sendAppointmentEmail = (data, params = {}) => {
    const { appointmentData, otherParty, currentUser, currentListing, intl } = data;

    sendSGEmail({
        recipientId: otherParty.id.uuid,
        emailTemplate: 'appointmentRequested',
        groupIdName: 'userTransactionChatsGroupId',
        data: {
            recipientFirstName: currentUser.attributes.profile.firstName,
            otherPartyName: otherParty.attributes.profile.firstName,
            listingTitle: currentListing.attributes.title,
            date: intl.formatDate(appointmentData.data.date, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            }),
            time: appointmentData.data.time,
            location: appointmentData.data.location,
            chatPageUrl: window.location.href,
        },
        ...params,
    });
};

export const sendAppointment = data => async (dispatch, getState, sdk) => {
    const { otherParty, currentUser, currentListing, intl, ...appointmentData } = data;

    dispatch(sendAppointmentRequest());
    const defaultErrorMessage = 'Failed to send a message for appointment.';

    return fetch('/api/appointment/create', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(appointmentData),
    })
        .then(res => res.json())
        .then(data => {
            const { error, message } = data;

            if (error) {
                throw new Error(message || defaultErrorMessage);
            }
            /**
             * see interceptSendMessage for uuid use
             */
            const { transactionId } = appointmentData;
            dispatch(sendAppointmentSuccess({ uuid: transactionId }));

            sendAppointmentEmail(
                { appointmentData, otherParty, currentUser, currentListing, intl },
                {
                    emailTemplate: 'appointmentRequested',
                }
            );

            dispatch(
                addAppointmentEntity({
                    transactionId,
                    entity: data,
                })
            );
            return {};
        })
        .catch(error => dispatch(sendAppointmentError(error.message)));
};

export const proceedWithAppointment = (data, action) => (dispatch, getState, sdk) => {
    const config = {
        [ACCEPT_APPOINTMENT_REQUEST]: {
            status: APPOINTMENT_ACCEPTED_STATUS,
            emailTemplateName: 'appointmentAccepted',
        },
        [DECLINE_APPOINTMENT_REQUEST]: {
            status: APPOINTMENT_DECLINED_STATUS,
            emailTemplateName: 'appointmentDeclined',
        },
        [CANCEL_APPOINTMENT_REQUEST]: {
            status: APPOINTMENT_CANCELED_STATUS,
            emailTemplateName: 'appointmentCanceled',
        },
    };

    const { otherParty, currentUser, currentListing, intl, appointmentData } = data;
    const { transactionId } = appointmentData;

    const actionMaybe = config[action];
    const wrongActionMaybe = !action || !actionMaybe;

    if (wrongActionMaybe) throw new Error(`Unexpected transaction action ${action}.`);
    if (!transactionId) throw new Error(`Unexpected transaction id ${transactionId}.`);

    dispatch(sendAppointmentRequest());
    /**
     * update an appointment object data and create
     * a new transition status concerning the appointment
     * */
    const { status: statusUpdated, emailTemplateName } = actionMaybe;
    return fetch(`/api/appointment/${transactionId}/${statusUpdated}/change-status`, {
        method: 'PATCH',
    })
        .then(res => res.json())
        .then(data => {
            const { error, message, appointment, transition } = data;
            if (error) throw new Error(message);

            if (!appointment || !transition) {
                const failedData = !appointment ? 'appointment.data' : 'transition.data';
                throw new Error(`Some data failed to update: ${failedData}.`);
            }
            /**
             * see interceptSendMessage for uuid use
             */
            dispatch(mergeAppointmentEntity(appointment));
            dispatch(updateAppointmentSuccess({ uuid: transactionId }));
            dispatch(
                addAppointmentEntity({
                    transactionId,
                    entity: transition,
                })
            );

            sendAppointmentEmail(
                { appointmentData, otherParty, currentUser, currentListing, intl },
                {
                    emailTemplate: emailTemplateName,
                }
            );

            return {};
        })
        .catch(error => dispatch(sendAppointmentError(error.message)));
};

export const updateChatLastActivity = txId => (dispatch, getState, sdk) => {
    const {
        user: { currentUser },
    } = getState();

    if (!currentUser) {
        return;
    }

    const {
        attributes: { profile },
    } = currentUser;

    const { publicData } = profile;
    const { uuid } = txId;

    const today = new Date();
    const chatLastActivity = {
        ...publicData.chatLastActivity,
        [uuid]: { lastVisit: today.toString() },
    };

    const params = {
        publicData: {
            chatLastActivity,
        },
    };

    const currentUserDataUpd = { ...currentUser };
    currentUserDataUpd.attributes.profile.publicData.chatLastActivity = chatLastActivity;

    return dispatch(updateUserProfileInfo(params, currentUserDataUpd));
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = params => async (dispatch, getState) => {
    const inboxSearch = '';
    const inboxParams = {
        // tab: params.transactionRole === 'customer' ? 'orders' : 'sales',
    };

    // if (!params.id) {
    //     return dispatch(inboxLoadData(inboxParams, inboxSearch));
    // }

    const txId = new UUID(params.id);
    const state = getState().TransactionPage;
    const txRef = state.transactionRef;
    // const txRole = params.transactionRole;

    // In case a transaction reference is found from a previous
    // data load -> clear the state. Otherwise keep the non-null
    // and non-empty values which may have been set from a previous page.
    const initialValues = txRef ? {} : pickBy(state, isNonEmpty);

    dispatch(setInitialValues(initialValues));
    /**
     * inboxLoadData - fetch user's transactions;
     *
     * inboxLoadData goes first to collect all txs data;
     * after ild is successfull, the corresponding
     * tx is found inside tx page
     *
     * otherwise inboxLoadData erases this specific fields
     * in the marketplace entities data
     */
    await dispatch(inboxLoadData(inboxParams, inboxSearch));
    // Sale / order (i.e. transaction entity in API)
    return Promise.all([
        // dispatch(inboxLoadData(inboxParams, inboxSearch)),
        // dispatch(fetchTransaction(txId, txRole)),
        dispatch(fetchMessages(txId, 1)),
        /**
         * appointments are fetched at InboxPage.duck
         * as soon as transactions data is received
         */
        dispatch(fetchNextTransitions(txId)),
        dispatch(updateChatLastActivity(txId)),
    ]).then(() => dispatch(markUnreadMessageAsViewed(txId.uuid)));
};
