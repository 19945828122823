import React from 'react';
import { Form as FinalForm } from 'react-final-form';

import { Form, Button } from '../../components';
import { InviteReferalsForm } from '../../forms';
import { ProtectionInfoIcon } from '../../icons';

import css from './EditUserInfoPage.css';

const defaultRecommendationMessage = `Hallo

Ich hoffe, dir geht es gut.

Ich bin seit kurzem Mitglied bei HorseDeal, der Sharing-Plattform für Pferde. Um die Sichtbarkeit meines Profils zu steigern, würde ich mich freuen, wenn Du dir eine Minute Zeit nehmen würdest, um mir eine Empfehlung zu schreiben.

Vielen Dank im Voraus für deine Zeit!`;

export default ({ navigateToPreviousForm, updateInfoInProgress, children, ...restProps }) => (
    <FinalForm
        initialValues={{
            message: defaultRecommendationMessage,
        }}
        {...restProps}
        render={fieldRenderProps => {
            const { form, handleSubmit, values } = fieldRenderProps;
            const { recipientsList, message } = values;
            const disabled = !recipientsList || recipientsList.length === 0 || !message;

            return (
                <>
                    <Form onSubmit={handleSubmit} className={css.form}>
                        <h3 className={css.collectInfoHeader}>Empfehlungen erhlalten</h3>
                        <InviteReferalsForm
                            form={form}
                            messageRequired
                            recipientsList={recipientsList}
                        />
                        <p className={css.msgSection}>
                            <ProtectionInfoIcon />
                            Die E-Mail-Adresse der Empfänger wird nicht gespeichert.
                        </p>
                    </Form>
                    <Button disabled={disabled} onClick={() => restProps.onSubmit(values)}>
                        Einladung senden
                    </Button>
                </>
            );
        }}
    />
);
