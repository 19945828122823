import { createListingFromTempData, provideSignupAnalytics } from '../../ducks/Auth.duck';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { checkMarketplaceCurrentUser } from '../../util/data';
import { calculateAge } from '../../util/user';
import { fetchCurrentUser, updateUserProfileInfo } from '../../ducks/user.duck';
import {
    commonDisciplines,
    PART_TIME_OPTION_FULL,
    PART_TIME_OPTION_INDEF,
    PART_TIME_OPTION_TEMP,
} from '../../marketplace-custom-config';
import config from '../../config';
import {
    removeSingleAsset,
    requestEntityAssetsList,
    sendSGEmail,
    updateEntityAssetsMetadata,
    uploadSingleAsset,
    uploadSingleFile,
} from '../../util/api';
import { getUserCountry, getUserGeocodingData } from '../../util/location';
import { excludeTypes } from '../../components/LocationAutocompleteInput/LocationAutocompleteInputImpl.helper';
import { checkResponseError, DEFAULT_VARIANT } from '../../ducks/Assets.duck';

const {
    userTypeRider,
    listingTypeHorse,
    listingTypeRider,
    canonicalRootURL,
    maps: { supportedCountries },
} = config;

export const DISCARD_ERRORS = 'app/OnboardingPage/DISCARD_ERRORS';

export const UPLOAD_DOCUMENT_REQUEST = 'app/OnboardingPage/UPLOAD_DOCUMENT_REQUEST';
export const DISCARD_DOCUMENT_REQUEST = 'app/OnboardingPage/DISCARD_DOCUMENT_REQUEST';
export const UPLOAD_DOCUMENT_SUCCESS = 'app/OnboardingPage/UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_FAILED = 'app/OnboardingPage/UPLOAD_DOCUMENT_FAILED';
export const CHANGE_METADATA_DOCUMENT_REQUEST =
    'app/OnboardingPage/CHANGE_METADATA_DOCUMENT_REQUEST';
export const CHANGE_METADATA_DOCUMENT_SUCCESS =
    'app/OnboardingPage/CHANGE_METADATA_DOCUMENT_SUCCESS';

export const REMOVE_DOCUMENTS_REQUEST = 'app/OnboardingPage/REMOVE_DOCUMENTS_REQUEST';
export const REMOVE_SINGLE_DOCUMENT_SUCCESS = 'app/OnboardingPage/REMOVE_SINGLE_DOCUMENT_SUCCESS';
export const REMOVE_ALL_DOCUMENTS_SUCCESS = 'app/OnboardingPage/REMOVE_ALL_DOCUMENTS_SUCCESS';
export const REMOVE_DOCUMENTS_FAILED = 'app/OnboardingPage/REMOVE_DOCUMENTS_FAILED';

export const FETCH_DOCUMENTS_REQUEST = 'app/OnboardingPage/FETCH_DOCUMENTS_REQUEST';
export const FETCH_DOCUMENTS_SUCCESS = 'app/OnboardingPage/FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_FAILED = 'app/OnboardingPage/FETCH_DOCUMENTS_FAILED';

export const PROMPT_USER_BIO_REQUEST = 'app/OnboardingPage/PROMPT_USER_BIO_REQUEST';
export const PROMPT_USER_BIO_SUCCESS = 'app/OnboardingPage/PROMPT_USER_BIO_SUCCESS';
export const PROMPT_USER_BIO_FAILED = 'app/OnboardingPage/PROMPT_USER_BIO_FAILED';

const merge = (docs = [], stateDocs, userId) =>
    docs.reduce(
        (acc, doc) => {
            try {
                const { metadata, Key, ...restData } = doc;
                const { type } = metadata;

                const [, fileName] = Key.split(`${userId}/`);
                const docData = { ...restData, ...metadata, Key, fileName };

                if (!acc[type]) {
                    acc[type] = [];
                }

                return { ...acc, [type]: [...acc[type], docData] };
            } catch (e) {
                return acc;
            }
        },
        { ...stateDocs }
    );

const initialState = {
    userDocuments: {},
    uploadDocumentsInProgress: false,
    uploadDocumentsError: null,
    /** additional info about the field where error occured  */
    userDocErrorMetadata: null,
    promptUserBioInProgress: false,
    promptUserBioError: null,
    promptUserBio: null,
};

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case PROMPT_USER_BIO_REQUEST:
            return {
                ...state,
                promptUserBioError: null,
                promptUserBio: null,
                promptUserBioInProgress: true,
            };
        case PROMPT_USER_BIO_SUCCESS:
            return {
                ...state,
                promptUserBioError: null,
                promptUserBioInProgress: false,
                promptUserBio: payload,
            };
        case PROMPT_USER_BIO_FAILED:
            return {
                ...state,
                promptUserBioInProgress: false,
                promptUserBioError: payload,
                promptUserBio: null,
            };

        case DISCARD_ERRORS:
            return {
                ...state,
                uploadDocumentsError: null,
                promptUserBioError: null,
                userDocErrorMetadata: null,
            };
        case UPLOAD_DOCUMENT_REQUEST:
            return {
                ...state,
                uploadDocumentsInProgress: true,
                uploadDocumentsError: null,
                userDocErrorMetadata: null,
            };
        case DISCARD_DOCUMENT_REQUEST:
            return {
                ...state,
                userDocuments: {},
            };
        case UPLOAD_DOCUMENT_SUCCESS:
            return {
                ...state,
                userDocuments: {
                    ...state.userDocuments,
                    ...payload,
                },
                uploadDocumentsInProgress: false,
                uploadDocumentsError: null,
                userDocErrorMetadata: null,
            };
        case UPLOAD_DOCUMENT_FAILED:
            return {
                ...state,
                uploadDocumentsInProgress: false,
                uploadDocumentsError: payload.message,
                userDocErrorMetadata: payload.metadata,
            };
        case CHANGE_METADATA_DOCUMENT_REQUEST:
            return {
                ...state,
                uploadDocumentsInProgress: true,
                uploadDocumentsError: null,
                userDocErrorMetadata: null,
            };
        case CHANGE_METADATA_DOCUMENT_SUCCESS:
            return {
                ...state,
                uploadDocumentsInProgress: false,
                uploadDocumentsError: null,
                userDocErrorMetadata: null,
            };

        case REMOVE_DOCUMENTS_REQUEST:
            return {
                ...state,
                /**
                 * if remove doc fails, we don't want to
                 * show a user an empty images list;
                 * instead an error message has to be shown
                 * with no changes to the list
                 */
                uploadDocumentsInProgress: true,
                uploadDocumentsError: null,
                userDocErrorMetadata: null,
            };
        case REMOVE_SINGLE_DOCUMENT_SUCCESS: {
            const documents = { ...state.userDocuments };
            const { Key: KeyToRemove, type } = payload;

            return {
                ...state,
                uploadDocumentsInProgress: false,
                uploadDocumentsError: null,
                userDocErrorMetadata: null,
                userDocuments: {
                    ...documents,
                    [type]: [...(documents[type] || []).filter(({ Key }) => Key !== KeyToRemove)],
                },
            };
        }
        case REMOVE_ALL_DOCUMENTS_SUCCESS: {
            return {
                ...state,
                userDocuments: {},
                uploadDocumentsInProgress: false,
                uploadDocumentsError: null,
                userDocErrorMetadata: null,
            };
        }
        case REMOVE_DOCUMENTS_FAILED: {
            return {
                ...state,
                uploadDocumentsInProgress: false,
                uploadDocumentsError: payload,
                /** add error metadata if needed */
            };
        }

        default:
            return state;
    }
}

export const discardErrors = () => ({
    type: DISCARD_ERRORS,
});

export const promptUserBioRequest = () => ({
    type: PROMPT_USER_BIO_REQUEST,
});
export const promptUserBioSuccess = data => ({
    type: PROMPT_USER_BIO_SUCCESS,
    payload: data,
});
export const promptUserBioError = error => ({
    type: PROMPT_USER_BIO_FAILED,
    payload: error,
});

export const uploadDocumentRequest = () => ({
    type: UPLOAD_DOCUMENT_REQUEST,
});
export const discardDocumentRequest = () => ({
    type: DISCARD_DOCUMENT_REQUEST,
});

export const uploadDocumentSuccess = data => ({
    type: UPLOAD_DOCUMENT_SUCCESS,
    payload: data,
});
export const uploadDocumentError = error => ({
    type: UPLOAD_DOCUMENT_FAILED,
    payload: error,
});

export const changeDocumentMetadataRequest = () => ({
    type: CHANGE_METADATA_DOCUMENT_REQUEST,
});
export const changeDocumentMetadataSuccess = () => ({
    type: CHANGE_METADATA_DOCUMENT_SUCCESS,
});

export const removeDocumentRequest = () => ({
    type: REMOVE_DOCUMENTS_REQUEST,
});
export const removeAllDocumentsSuccess = () => ({
    type: REMOVE_ALL_DOCUMENTS_SUCCESS,
});
export const removeDocumentSuccess = ({ Key, type }) => ({
    type: REMOVE_SINGLE_DOCUMENT_SUCCESS,
    payload: { Key, type },
});
export const removeDocumentFailed = error => ({
    type: REMOVE_DOCUMENTS_FAILED,
    payload: error,
});

const dispatchDocsError = dispatch => e => {
    dispatch(
        uploadDocumentError(e && e.message ? { message: e.message, metadata: e.metadata } : true)
    );
    const isSuccessful = false;
    return isSuccessful;
};

/**
 * CHANGE DOC METADATA
 */
export const changeUserDocumentMetadata = (Key, metadata) => async dispatch => {
    // TODO remove this func and use Assets.duck.js methods instead
    dispatch(changeDocumentMetadataRequest());

    try {
        const body = JSON.stringify({ Key, metadata });
        const response = await updateEntityAssetsMetadata(body);
        const data = await response.json();

        checkResponseError(data, 'Failed to update metadata');
        dispatch(changeDocumentMetadataSuccess());

        const isSuccessful = true;
        return isSuccessful; // or any other non-falsy data might be returned
    } catch (e) {
        return dispatchDocsError(dispatch)(e);
    }
};

/**
 * REMOVE SINGLE DOC
 */
export const removeUserDocument = (Key, type) => async (dispatch, getState, sdk) => {
    // TODO remove this func and use Assets.duck.js methods instead
    dispatch(removeDocumentRequest());

    try {
        const body = JSON.stringify({ Key });
        const response = await removeSingleAsset(body);
        const data = await response.json();

        checkResponseError(data, 'Failed to remove');

        dispatch(removeDocumentSuccess({ Key, type }));

        const isSuccessful = true;
        return isSuccessful; // or any other non-falsy data might be returned
    } catch (e) {
        return dispatchDocsError(dispatch)(e);
    }
};
/**
 * UPLOAD SINGLE DOC
 */
export const uploadUserDocument = ({ file, metadata, type = 'common' }) => async (
    dispatch,
    getState,
    sdk
) => {
    // TODO remove this func and use Assets.duck.js methods instead
    dispatch(uploadDocumentRequest());

    const state = getState();

    const {
        id: { uuid },
    } = checkMarketplaceCurrentUser(state);

    const {
        OnboardingPage: { userDocuments },
    } = state;

    const params = {
        entityId: uuid,
        metadata,
        addPreview: true,
    };

    const formData = new FormData();

    formData.append('file', file);
    formData.append('content', JSON.stringify(params));

    // const apiRoute = type === 'asset' ? 'upload-asset' : 'upload';
    const apiEndpoint = type === 'asset' ? uploadSingleAsset : uploadSingleFile;

    try {
        const response = await apiEndpoint(formData);
        const data = await response.json();

        checkResponseError(data, 'Failed to upload');
        /**
         * check if assetsRequest might be used in this case;
         * if this is the case - remove this thunk
         */
        dispatch(uploadDocumentSuccess(merge([data], userDocuments, uuid)));
        const isSuccessful = true;
        return isSuccessful; // or any other non-falsy data might be returned
    } catch (e) {
        return dispatchDocsError(dispatch)({ message: e.message, metadata });
    }
};
/**
 * GET ALL DOCS
 */
export const getAllUserDocuments = uuid => async (dispatch, getState, sdk) => {
    dispatch(uploadDocumentRequest());
    dispatch(discardDocumentRequest());

    const state = getState();

    const {
        OnboardingPage: { userDocuments },
    } = state;

    try {
        const response = await requestEntityAssetsList(uuid, DEFAULT_VARIANT);
        const data = await response.json();

        if (data.error) {
            throw new Error(
                data.message || (typeof data.error === 'string' ? data.error : 'Failed to list')
            );
        }

        const filesExist = data && data.length;

        if (!filesExist) {
            dispatch(uploadDocumentSuccess({}));
            return;
        }

        const files = filesExist ? merge(data, userDocuments, uuid) : {};

        dispatch(uploadDocumentSuccess(files));
    } catch (e) {
        return dispatchDocsError(dispatch)(e);
    }
};

export const submitRecommendations = ({ recipientsList, message, recipientFirstName }) => () => {
    return fetch('/api/recommendations/recommend', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ recipientsList, message, recipientFirstName }),
    });
};

export const promptUserBio = () => async (dispatch, getState) => {
    const state = getState();

    const {
        attributes: { profile },
    } = checkMarketplaceCurrentUser(state);
    const {
        firstName,
        publicData: {
            userType,
            birthDate,
            postalCode,
            city,
            interest,
            availability: pbAvailability,
            openForPartTimeProposals,
            desiredDisciplines,
            riderQualification,
        },
    } = profile;

    const availabilityConfig = {
        flexible: 'Ich bin flexibel',
        [PART_TIME_OPTION_INDEF]: 'Etwas Unbefristetes',
        [PART_TIME_OPTION_TEMP]: 'Etwas Befristetes',
        [PART_TIME_OPTION_FULL]: 'Ich bin offen',
    };
    const openForPTPConfig = {
        [PART_TIME_OPTION_INDEF]: 'Etwas Unbefristetes',
        [PART_TIME_OPTION_TEMP]: 'Etwas Befristetes',
        [PART_TIME_OPTION_FULL]: 'Etwas Unbefristetes oder etwas Befristetes',
    };
    const role = userType === 'rider' ? 'Reiter' : 'Pferdebesitzer';
    const availability = availabilityConfig[pbAvailability] || `${pbAvailability} pro Woche`;

    dispatch(promptUserBioRequest());

    try {
        const prompt = `Erstelle mir einen Profiltext aus den folgenden Informationen:
    Name: ${firstName}
    Rolle: ${role}
    Alter: ${calculateAge(birthDate)} Jahre
    Suche in: ${postalCode} ${city}
    Interessen: ${(interest || []).join(', ')}
    Verfügbarkeit: ${availability}
    Offen für: ${openForPTPConfig[openForPartTimeProposals]}
    Disziplinen: ${(desiredDisciplines || [])
        .map(
            disciplineKey =>
                (commonDisciplines.find(({ key }) => key === disciplineKey) || {}).label ||
                disciplineKey
        )
        .join(', ')}
    Qualifikationen: ${(riderQualification || []).join(', ')}
    Füge hinzu: Für mehr Informationen, schaue gerne auf meinem Profil vorbei oder sende mir eine Anfrage.`;

        const response = await fetch(`/api/openai/prompt`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ prompt }),
        });

        const data = await response.json();

        if (data.error) {
            throw new Error(data.error.message);
        }

        if (!data.text) {
            throw new Error('Text generation failed.');
        }

        dispatch(promptUserBioSuccess(data.text));
    } catch (error) {
        dispatch(promptUserBioError(error.message || error));
    }
};

export const resolveCollectingUserInfo = (
    publicData = {},
    profileAttr = {},
    lastStep = false
) => async (dispatch, getState) => {
    if (lastStep) {
        dispatch(provideSignupAnalytics());
        const user = getState().user.currentUser;

        const currentUserDataUpd = { ...user };
        currentUserDataUpd.attributes.profile.publicData.infoCollected = true;
        const insertBioMaybe = profileAttr.bio;

        if (insertBioMaybe) {
            currentUserDataUpd.attributes.profile.bio = profileAttr.bio;
        }

        dispatch(
            updateUserProfileInfo(
                {
                    ...profileAttr,
                    publicData: {
                        ...publicData,
                        infoCollected: true,
                    },
                    protectedData: {
                        joinDate: new Date().toISOString(),
                    },
                },
                currentUserDataUpd
            )
        );
        /**
         * send sendGrid email on user created event
         * the email template depends on userType, postalCode, city
         */

        const { userType, postalCode, city } = user.attributes.profile.publicData;
        const publicAddress = `${postalCode} ${city}`;

        getUserGeocodingData(publicAddress, excludeTypes)
            .then(({ bounds, origin }) => {
                if (!bounds || !origin) {
                    throw new Error();
                }

                const userCountryCode = getUserCountry();
                const userCountryStr = (
                    supportedCountries[userCountryCode] || supportedCountries['CH']
                ).name;
                const boundsStr = [bounds.ne.lat, bounds.ne.lng, bounds.sw.lat, bounds.sw.lng].join(
                    '%2C'
                );
                const originStr = [origin.lat, origin.lng].join('%2C');
                const publicAddressStr = [postalCode, city].join('%2C');

                const isRider = userType === userTypeRider;
                const templateByUserRole = isRider
                    ? 'userCreatedRiderTemplate'
                    : 'userCreatedHorseownerTemplate';

                const listingType = isRider ? listingTypeHorse : listingTypeRider;
                const params = {
                    recipientEmail: user.attributes.email,
                    emailTemplate: templateByUserRole,
                    groupIdName: 'ownAccountGroupId',
                    data: {
                        postalCode,
                        city,
                        recipientFirstName: user.attributes.profile.firstName,
                        canonicalRootURL,
                        searchPath: `/s?address=${publicAddressStr}%2C%20${userCountryStr}&bounds=${boundsStr}&origin=${originStr}&listingType=${listingType}`,
                    },
                };

                sendSGEmail(params);
            })
            .catch(e => {
                // do nothing
            });
        dispatch(fetchCurrentUser());
        await dispatch(createListingFromTempData());
        return Promise.resolve({});
    }
    return dispatch(updateProfile(profileAttr, publicData));
};
