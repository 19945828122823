import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, SecondaryButton, ReviewModal } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './AcceptDeclineRequestButtonsMaybe.css';
import { parse } from '../../util/urlHelpers';
import { compose } from 'redux';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { FILTER_SEARCH_PARAM } from '../../containers/TransactionPage/components/Filters';
import { useParamFilters } from '../../hooks/useParamFilters';
import { trimDisplayNameLastWord } from '../../util/data';

const AcceptDeclineRequestButtonsMaybe = ({
    onrespondOnCustomerInquiry,
    transactionRequestInProgress,
    inquiryError,
    revieweeName,
    currentUser,
    currentListing,
    otherParty,
    match,
    location,
    history,
}) => {
    const { id } = match.params;
    const {
        attributes: {
            profile: {
                displayName,
                publicData: { userType: otherPartyUserType },
            },
        },
    } = otherParty;
    const { feedback: declineModalVisible } = parse(location.search) || {};

    const otherPartyName = trimDisplayNameLastWord(displayName);

    const filters = useParamFilters(FILTER_SEARCH_PARAM, location);
    const hasFilters = filters && filters.length > 0;

    const setDeclineModalVisibility = () => {
        if (declineModalVisible) {
            return history.goBack();
        } else {
            const pagename = id ? 'OrderDetailsPage' : 'OrderMessagesPage';

            history.push(
                createResourceLocatorString(
                    pagename,
                    routeConfiguration(),
                    { id },
                    hasFilters
                        ? {
                              [FILTER_SEARCH_PARAM]: filters.join(encodeURIComponent(',')),
                              feedback: 'true',
                          }
                        : { feedback: 'true' }
                )
            );
        }
    };

    return (
        <div className={css.acceptDeclineBtnSection}>
            <p className={css.acceptDeclineBtnHeading}>
                <FormattedMessage
                    id="TransactionPanel.acceptDeclineBtnHeading"
                    values={{ userName: otherPartyName }}
                />
            </p>
            <p className={css.acceptDeclineBtnDesc}>
                <FormattedMessage
                    id="TransactionPanel.acceptDeclineBtnDesc"
                    values={{ userName: otherPartyName }}
                />
            </p>
            <aside className={css.buttonsSection}>
                <Button
                    onClick={() => onrespondOnCustomerInquiry()}
                    inProgress={transactionRequestInProgress}
                >
                    <FormattedMessage id="TransactionPanel.acceptInquiryByProvider" />
                </Button>
                <SecondaryButton
                    onClick={() => setDeclineModalVisibility(true)}
                    inProgress={transactionRequestInProgress}
                >
                    <FormattedMessage id="TransactionPanel.declineInquiryByProvider" />
                </SecondaryButton>
            </aside>
            <ReviewModal
                id="ReviewOrderModal"
                isOpen={declineModalVisible}
                onClose={setDeclineModalVisibility}
                onrespondOnCustomerInquiry={({ feedback, reason }) => {
                    onrespondOnCustomerInquiry(feedback, reason);
                    setDeclineModalVisibility();
                }}
                revieweeName={revieweeName}
                otherPartyUserType={otherPartyUserType}
                otherParty={otherParty}
                currentUser={currentUser}
                currentListing={currentListing}
                inquiryError={inquiryError}
                transactionRequestInProgress={transactionRequestInProgress}
            />
            {inquiryError && (
                <span className={css.error}>
                    Entschuldigen Sie, etwas ist schiefgelaufen. Versuchen Sie, die Seite neu zu
                    laden oder wenden Sie sich an den Support.
                </span>
            )}
        </div>
    );
};

export default compose(withRouter)(AcceptDeclineRequestButtonsMaybe);
